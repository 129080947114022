import useElementSize from "hooks/useElementSize";
import { createContext, useContext, useState } from "react";
import { Transition } from "react-transition-group";

const ContainerContext = createContext({
  focusTab: 0,
  setFocusTab: (tab: number) => {},
  tabWidth: 0,
  animationDuration: 0,
  numTabs: 0,
});

function Container({ children }: { children: any }) {
  const [tabContainerRef, { width: containerWidth }] = useElementSize();
  const [focusTab, setFocusTab] = useState(0);
  const animationDuration = 300;
  const numTabs = children.length;
  const tabWidth = (containerWidth - 16) * (2 / 3);

  return (
    <ContainerContext.Provider
      value={{
        focusTab,
        setFocusTab,
        tabWidth,
        animationDuration,
        numTabs,
      }}
    >
      <div ref={tabContainerRef} className="d-flex mb-5">
        {children}
      </div>
    </ContainerContext.Provider>
  );
}

const TabContext = createContext({
  isFocus: false,
});

function Tab({ children, i }: { children: any; i: number }) {
  const { focusTab, setFocusTab, numTabs, animationDuration } = useContext(ContainerContext);
  const isFocus = i === focusTab;
  const flex = isFocus ? (numTabs - 1) * 2 : 1;

  const defaultStyle = {
    overflow: "hidden",
  };

  const transitionStyles: { [key: string]: any } = {
    entering: { flex, transition: `all ${animationDuration}ms` },
    entered: { flex },
    exiting: { flex: 1, transition: `all ${animationDuration}ms` },
    exited: { flex: 1 },
  };

  return (
    <TabContext.Provider value={{ isFocus }}>
      <Transition in={isFocus} timeout={animationDuration}>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              transition: `all ${animationDuration}ms`,
              flex,
              overflow: "hidden",
            }}
          >
            <div className="py-2 h-100 ">
              <div
                className="bg-tan-3 border mx-1 rounded h-100 d-flex justify-content-center position-relative"
                onClick={() => setFocusTab(i)}
                style={{
                  cursor: isFocus ? "" : "pointer",
                  overflow: "hidden",
                }}
              >
                <div>{children}</div>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </TabContext.Provider>
  );
}

function Header({ children }: { children: React.JSX.Element }) {
  return <div>{children}</div>;
}

function Content({ children }: { children: React.JSX.Element }) {
  const { isFocus } = useContext(TabContext);
  const { tabWidth, animationDuration } = useContext(ContainerContext);

  const defaultStyle = {
    overflow: "hidden",
  };

  const transitionStyles: { [key: string]: any } = {
    entering: { width: tabWidth, transition: `all ${animationDuration}ms` },
    entered: { width: tabWidth },
    exiting: { width: 0, transition: `all ${animationDuration}ms` },
    exited: { width: 0 },
  };
  return (
    <Transition in={isFocus} timeout={animationDuration}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <div style={{ width: tabWidth }}>{children}</div>
        </div>
      )}
    </Transition>
  );
}

const HorizontalAccordion = {
  Container,
  Tab,
  Header,
  Content,
};

export default HorizontalAccordion;

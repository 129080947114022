import { Link } from "react-router-dom";
import { TConcept, TPathway, TFocus } from "types/JsonType";
import RightArrow from "../bootstrap_icons/RightArrow";

type TBreadcruptLinkProps = {
  link: { name: string; url: string };
  i: number;
};

function BreadcrumbLink({ link, i }: TBreadcruptLinkProps) {
  const focusLink = (
    <>
      <span className="text-body fs-7">{link.name[0]}</span>
      {link.name.slice(1)}
    </>
  );
  const normalLink = link.name;
  return (
    <>
      <Link
        key={link.name}
        to={link.url}
        className="no-underline underline-on-hover text-grey-red mx-1 no-word-break no-word-wrap"
      >
        {i === 1 ? focusLink : normalLink}
      </Link>
      <RightArrow key={link.url} />
    </>
  );
}

function LastElement({ link, i }: TBreadcruptLinkProps) {
  const normalLastElement = (
    <span key={link.name} className="no-word-break no-word-wrap mx-1">
      {link.name}
    </span>
  );
  const focusLastElement = (
    <span key={link.name} className="no-word-break no-word-wrap mx-1">
      <span className="text-grey-red fs-7">{link.name[0]}</span>
      {link.name.slice(1)}
    </span>
  );
  return i === 1 ? focusLastElement : normalLastElement;
}

type TBreadcrumbProps = {
  focus: TFocus;
  pathway?: TPathway;
  concept?: TConcept;
};
export function Breadcrumb({ focus, pathway, concept }: TBreadcrumbProps) {
  const links = [
    {
      name: "FOCUS",
      url: "/FocusHome",
    },
    {
      name: focus.name,
      url: focus.url,
    },
  ];
  if (pathway) {
    links.push({
      name: pathway.name,
      url: pathway.url,
    });
  }
  if (concept) {
    links.push({
      name: concept.name,
      url: concept.url,
    });
  }

  return (
    <div className="bg-body px-2 py-1 round-bottom-end border fs-8 fw-bold">
      <div className="d-flex align-items-center">
        {links.map((link, i) => {
          const isLastElement = i === links.length - 1;
          return isLastElement ? (
            <LastElement key={link.name} link={link} i={i} />
          ) : (
            <BreadcrumbLink key={link.name} link={link} i={i} />
          );
        })}
      </div>
    </div>
  );
}

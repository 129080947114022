import constants from "Constants";
import MasterSidebar from "components/navigation/MasterSidebar";
import SidebarPage from "components/templates/SidebarPage";
import Pathways from "lib/data/Pathways";
import { useContext, useEffect, useState, createContext } from "react";
import { Link, useParams } from "react-router-dom";
import { TPathway, TFocus, defaultFocus, defaultPathway } from "types/JsonType";
import Focuses from "lib/data/Focuses";
import BreadcrumbsAndArrows from "components/navigation/BreadcrumbsAndArrows";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import Error from "components/templates/Error";

type TPathwayContext = {
  pagePathway: TPathway;
  pageFocus: TFocus;
};
const PathwayContext = createContext<TPathwayContext>({
  pagePathway: defaultPathway,
  pageFocus: defaultFocus,
});

function Title() {
  const { pagePathway, pageFocus } = useContext(PathwayContext);

  return (
    <div className="text-center my-4">
      <div className="display-3">{pagePathway.name}</div>
      <div className="lead fs-5">
        <Link to={pageFocus.url} className="text-body no-underline underline-on-hover">
          <span className="text-grey-red fs-3"> {pageFocus.letter}</span>
          {pageFocus.name.slice(1)}
        </Link>
      </div>
    </div>
  );
}
function Content() {
  const { pagePathway } = useContext(PathwayContext);

  const [ImportedComponent, setImportedComponent] = useState(null) as any;

  useEffect(() => {
    async function ImportComponents(filePath: string) {
      setImportedComponent(null);
      await import(`${filePath}`).then((module) => {
        const AnotherComponent = module.default;
        setImportedComponent(<AnotherComponent />);
      });

      // import all adjacent pathway components.This dramatically improves the load time of the pathway pages.
      Object.values(Pathways)
        .filter((pathway) => pathway.focus === pagePathway.focus)
        .forEach((pathway) => {
          import(`${pathway.filePath}`);
        });
    }

    ImportComponents(pagePathway.filePath);
  }, [pagePathway.filePath, pagePathway.focus]);

  return <div> {ImportedComponent}</div>;
}

export default function PathwayRoot() {
  const pathwayId = useParams().pathwayId;

  if (!pathwayId) {
    return <Error message={"404. Page not found"} />;
  }
  const pagePathway = Pathways[pathwayId];

  if (!pagePathway) {
    return <Error message={"404. Page not found"} />;
  }
  const pageFocus = Focuses[pagePathway.focus];

  SetDocumentTitle(pagePathway.name);

  return (
    <PathwayContext.Provider value={{ pagePathway, pageFocus }}>
      <SidebarPage.Page>
        <SidebarPage.Sidebar>
          <MasterSidebar />
        </SidebarPage.Sidebar>
        <SidebarPage.Content>
          <BreadcrumbsAndArrows focus={pageFocus} pathway={pagePathway} />
          <div className="d-flex justify-content-center mb-5 mx-3 mx-md-4">
            <div className="w-100" style={{ maxWidth: constants.maxPageWidth }}>
              <Title />
              <Content />
            </div>
          </div>
        </SidebarPage.Content>
      </SidebarPage.Page>
    </PathwayContext.Provider>
  );
}

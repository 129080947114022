import Definition from "components/concepts/templates/Definition";
import ExampleContainer from "../templates/ExampleContainer";
import Example from "../templates/Example";
export default function ConsumptionChain() {
  return (
    <div>
      <Definition>
        <p>
          The consumption chain model is an ideal tool for innovation, the consumption chain answers
          the question of “Where can we innovate?” when it feels like everything has already been
          done. It provides insights into the entire consumer journey, helping firms identify
          touchpoints, influences, and opportunities to enhance customer experience or to attract
          new customers.
        </p>

        <p>
          To construct a consumption chain model, firms should break down the consumer journey into
          key stages. These stages vary somewhat across firms but may include raising awareness,
          search, transaction, method of delivery, payment, setup, use, disposal, etc. Notice that
          each stage involves specific interactions and touchpoints between the consumer and the
          firm, each representing an opportunity to differentiate. Firms should outline each step of
          their consumer's consumption chain and then identify their customer's experience in each
          of these stages.
        </p>

        <p>
          The model aims to capture the holistic consumer experience. By understanding the entire
          consumption chain, firms can tailor their strategies to meet consumer needs at each stage.
          Identifying key areas for differentiation and innovation.
        </p>
      </Definition>
      <ExampleContainer>
        <Example header="Example 1">
          Firms have been differentiating by examining their consumption chain for decades. Consider
          your decision when going out to a restaurant to eat. Your options consist of drive-thru
          fast food, order at the window, order at the counter, a waiter serving your food, and many
          more options. Restaurants such as Sonic even differentiated by delivering your food to
          your parking space on rollerblades! This wide variety of offerings is differentiated
          through only one step of the consumption chain, the delivery of food. As we examine our
          products from the perspective of the consumption chain, a firm's opportunity for
          innovation and differentiation can expand significantly.
        </Example>
        <Example header="Example 2">
          For over a century, cars have been purchased and sold by large car dealerships. Any
          American is familiar with the large car lot, and a bright showroom at the center, all of
          which are swarming with pushy salesmen. Then in 2012, Carvana erected a car vending
          machine in Tempe, Arizona. While this fully functional vending machine is not their
          primary method of delivery, it is symbolic of their unique take on vehicle sales. Carvana
          has ushered in the era of online car sales, introducing features such as contactless car
          purchasing and same-day delivery of cars. This is another example of innovating through
          the consumption chain, as Carvana sells a completely undifferentiated product to the
          classic dealership, but with a completely different method of delivery.
        </Example>
      </ExampleContainer>
      <img
        src={require("lib/img/concepts/Consumption_Chain.png")}
        alt="Consumption Chain"
        className="w-100"
      />
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}

import { TLoginStatus } from "context/UserContext";
import { ValidateToken } from "api/auth/ValidateToken";
import { useEffect, useState } from "react";
import { TUser } from "types/UserType";

export default function useUser() {
  const [loginStatus, setLoginStatus] = useState<TLoginStatus>("loading");
  const [sessionExpiration, setSessionExpiration] = useState(Infinity);
  const [user, setUser] = useState<TUser>({
    id: NaN,
    email: "",
    isAdmin: false,
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      setLoginStatus("invalid");
      return;
    }

    async function Token() {
      const data = await ValidateToken();
      if (data.status === "success") {
        localStorage.setItem("token", data.token);
        localStorage.setItem("userId", String(data.user.id));
        setSessionExpiration(data.expires);
        setUser({ ...data.user });
        setLoginStatus("logged in");
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        setLoginStatus("invalid");
        console.error(data);
      }
    }

    Token();
  }, []);

  return { loginStatus, sessionExpiration, user, setUser };
}

import { TSaveP5FReq } from "@sharedTypes/req.type";
import { TErrorRes, TSaveP5FRes } from "@sharedTypes/res.type";
import constants from "Constants";
import missingParametersError from "api/Error";
import axios from "axios";

export async function SaveP5F(saveName: string, userUpdateValues: any) {
  const apiUrl = constants.apiUrl + "/tools/p5f/savep5f";

  const body: TSaveP5FReq = {
    userId: localStorage.getItem("userId"),
    token: localStorage.getItem("token"),
    saveName,
    forcesData: userUpdateValues,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TSaveP5FRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}

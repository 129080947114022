import { createContext } from "react";
import { TUser } from "types/UserType";
export type TLoginStatus = "loading" | "invalid" | "logged in";

type TUserContext = {
  loginStatus: TLoginStatus;
  user: TUser;
  setUser: (user: TUser) => void;
};

const UserContext = createContext<TUserContext>({
  loginStatus: "loading",
  user: {
    id: NaN,
    email: "",
    isAdmin: false,
    firstName: "",
    lastName: "",
  },
  setUser: (user: TUser) => {},
});

export default UserContext;

import { TChangePreferencesReq } from "@sharedTypes/req.type";
import { TChangePreferencesRes, TErrorRes } from "@sharedTypes/res.type";
import constants from "Constants";
import missingParametersError from "api/Error";
import axios from "axios";

export async function ChangePreferences(firstName: string, lastName: string) {
  const apiUrl = constants.apiUrl + "/auth/changepreferences";

  const body: TChangePreferencesReq = {
    userId: localStorage.getItem("userId"),
    token: localStorage.getItem("token"),
    firstName,
    lastName,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TChangePreferencesRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}

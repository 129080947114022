import { createContext, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TConcept, TPathway, TFocus, defaultConcept } from "types/JsonType";
import SidebarPage from "components/templates/SidebarPage";

import MasterSidebar from "components/navigation/MasterSidebar";
import Concepts from "lib/data/Concepts";
import constants from "Constants";
import Pathways from "lib/data/Pathways";
import Focuses from "lib/data/Focuses";
import BreadcrumbsAndArrows from "components/navigation/BreadcrumbsAndArrows";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import Error from "components/templates/Error";

type TConceptContext = {
  pageConcept: TConcept;
  pagePathway?: TPathway;
  pageFocus?: TFocus;
};

const ConceptContext = createContext<TConceptContext>({
  pageConcept: defaultConcept,
});

function SubHeading({ pagePathway }: { pagePathway: TPathway }) {
  const pageFocus = Focuses[pagePathway.focus];
  return (
    <div className="text-center">
      <div className="lead fs-5">
        <Link to={pageFocus.url} className="text-body no-underline underline-on-hover">
          <span className="fs-4 text-grey-red">{pageFocus.letter}</span>
          {pageFocus.name.slice(1)}
        </Link>{" "}
        &bull;{" "}
        <Link to={pagePathway.url} className="text-body no-underline underline-on-hover">
          {pagePathway.name}
        </Link>
      </div>
    </div>
  );
}

function Title() {
  const { pageConcept, pagePathway } = useContext(ConceptContext);
  return (
    <div className="text-center w-100 my-4">
      <div className="text-grey-red display-4"> {pageConcept.name}</div>
      {pagePathway && <SubHeading pagePathway={pagePathway} />}
    </div>
  );
}

function Content() {
  const { pageConcept, pagePathway } = useContext(ConceptContext);

  const [ImportedComponent, setImportedComponent] = useState(null) as any;

  useEffect(() => {
    async function ImportComponents(filePath: string) {
      setImportedComponent(null);
      await import(`${filePath}`).then((module) => {
        const AnotherComponent = module.default;
        setImportedComponent(<AnotherComponent />);
      });
    }

    // import all adjacent concept components. This dramatically improves the load time of the pages.
    if (pagePathway) {
      pagePathway.concepts.forEach((conceptId) => {
        const concept = Concepts[conceptId];
        import(`${concept.filePath}`);
      });
    }

    ImportComponents(pageConcept.filePath);
  }, [pageConcept.filePath, pagePathway]);

  return <div> {ImportedComponent}</div>;
}
export default function FocusRoot() {
  const { conceptId, pathwayId } = useParams();

  if (!conceptId) return <Error message={"404. Page not found"} />;
  const pageConcept = Concepts[conceptId];
  if (!pageConcept) return <Error message={"404. Page not found"} />;

  let pagePathway;
  if (pathwayId) {
    pagePathway = Pathways[pathwayId];
  }

  SetDocumentTitle(pageConcept.name);
  return (
    <ConceptContext.Provider value={{ pageConcept, pagePathway }}>
      <SidebarPage.Page sidebarDefaultCollapsed={!pagePathway}>
        <SidebarPage.Sidebar>
          <MasterSidebar />
        </SidebarPage.Sidebar>
        <SidebarPage.Content>
          {pagePathway && (
            <BreadcrumbsAndArrows
              focus={Focuses[pagePathway.focus]}
              pathway={pagePathway}
              concept={pageConcept}
            />
          )}
          <div className="d-flex justify-content-center mb-5 mx-3 mx-md-4">
            <div className="w-100" style={{ maxWidth: constants.maxPageWidth }}>
              <Title />
              <Content />
            </div>
          </div>
        </SidebarPage.Content>
      </SidebarPage.Page>
    </ConceptContext.Provider>
  );
}

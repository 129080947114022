import Example from "components/concepts/templates/Example";
import ExampleContainer from "components/concepts/templates/ExampleContainer";
import RelatedFrameworks from "components/concepts/templates/RelatedFrameworks";
import { Concepts } from "lib/data/Concepts";
import React from "react";

export default function UniqueValuePathway() {
  return (
    <div>
      <p>
        Once customers' jobs to be done and pain points are discovered, you understand what a
        product or service must achieve. Product differentiation explains how to create products and
        services that meet customers' needs, reduce pains, and are unique to alternatives. To
        understand the many different levers of a product or service that can be adjusted to meet
        customers' needs, the 3 Levels of Product is a great framework. It looks at three different
        levels of products: the core product, the actual product, and the augmented product.
      </p>
      <h4>Core Product</h4>
      <ul>
        <li>
          Jobs to be done: these are the primary reasons why your product or service exists in the
          first place
        </li>
      </ul>
      <h4>Actual Product</h4>
      <ul>
        <li>
          Brand: how does your brand impact consumers' perception of the product's ability to solve
          the jobs to be done? If you control multiple brands, which one would best satisfy target
          consumers' needs?
        </li>
        <li>
          Features: are any features missing that could help to solve the JTBD? Are any existing
          features unnecessary and can be removed?
        </li>
        <li>
          Quality Level: Does having a low quality prevent jobs from being done, or dissuade
          consumers from purchasing? Does having high quality solve any functional or emotional
          JTBD?
        </li>
        <li>
          Design: what about the design of the product can improve performance or perception of
          performance?
        </li>
        <li>
          Packaging: packaging can affect how consumers perceive your product - sustainable
          packaging, high-quality packaging, opportunity for messaging and branding
        </li>
      </ul>
      <h4>Augmented Product</h4>
      <ul>
        <li>
          Credit/Financing: do purchase options allow target consumers to actually be able to afford
          your product? How can deferred payments, pre-approvals, or other elements make your
          product more affordable without requiring a price decrease?
        </li>
        <li>
          Delivery: how the product is delivered, how available it is through channels that are
          available to consumers can make your product more appealing than competitive offerings
        </li>
        <li>
          Warranty: peace of mind from a good warranty; too generous of a warranty can be costly
        </li>
        <li>
          After-sales service & product support: how much support is needed for customers after
          purchasing? Can this level of support affect repeat purchases?"
        </li>
      </ul>

      <ExampleContainer>
        <Example header="Snack Companies">
          Snack companies have learned that different use cases require different packaging options.
          Many candy products are sold in various package types for different use cases: resealable
          bags are better for consumption over longer periods of time. Starburts unwrapped.
        </Example>
        <Example header="RIGID">
          RIDGID power tools are known for the outstanding warranty of their lithium-ion battery
          packs that power their cordless power tools. Many customers purchase their tools
          specifically because they want the benefit of a lifetime warranty for batteries, compared
          to the 2-3 year warranties that are offered by competitor brands.
        </Example>
      </ExampleContainer>

      <RelatedFrameworks
        concepts={[Concepts.StrategyCanvas]}
        unfinishedConcepts={[
          "Buyer Utility Map",
          "3 Levels of Product",
          "4 Types of Customer Value",
        ]}
      />
    </div>
  );
}

import React, { useEffect } from "react";
import { TMessage } from "types/MessageType";

type TMessageProps = {
  className?: string;
  message: TMessage | undefined;
  setMessage: React.Dispatch<React.SetStateAction<TMessage | undefined>>;
};

export default function Message({ className, message, setMessage }: TMessageProps) {
  const messageShowTime = 5000;
  const expires = Date.now() + messageShowTime;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Date.now() > expires) setMessage(undefined);
    }, messageShowTime);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [message, setMessage]);

  if (!message) {
    return null;
  }

  return (
    <div className={"text-center alert py-1 px-2 rounded alert-" + message.type + " " + className}>
      {message.message}
      <button
        className="btn-close float-end"
        onClick={() => setMessage(undefined)}
        aria-label="Close"
      ></button>
    </div>
  );
}

import { TPathways } from "types/JsonType";

export const Pathways = {
  DefineMissionPathway: {
    url: "/user/pathway/DefineMissionPathway",
    id: "DefineMissionPathway",
    question: "What is my company's purpose?",
    focus: "FoundationInPurpose",
    name: "Define Mission",
    imgSrc: require("lib/img/pathways/DefineMissionPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/FoundationInPurpose/DefineMissionPathway",
    summary:
      "The mission of a company defines what the purpose of the company is, or why the company exists.  Each Mission has a strategic element and a motivation element.  Not only does the mission send a message to those outside the company, it also sends a message to those inside the company, and the mission's effect on both must be considered.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  DefineVisionPathway: {
    url: "/user/pathway/DefineVisionPathway",
    id: "DefineVisionPathway",
    question: "What is my company's mission, vision, and values?",
    focus: "FoundationInPurpose",
    name: "Define Vision",
    imgSrc: require("lib/img/pathways/DefineVisionPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/FoundationInPurpose/DefineVisionPathway",
    summary:
      "The vision of a company describes what the future will look like if the organization has achieved its mission.  This can be a viewpoint outside of the company itself, or a statement that goes hand-in-hand with the mission statement.  Some examples of unique visions include Patagonia, with the mission, \"Save our Home Planet\" which doubles as a mission and vision, and Tesla's, which is 'an electric car without compromises.'  ",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  DefineValuesPathway: {
    url: "/user/pathway/DefineValuesPathway",
    id: "DefineValuesPathway",
    question: "What are my company's values?",
    focus: "FoundationInPurpose",
    name: "Define Values",
    imgSrc: require("lib/img/pathways/DefineValuesPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/FoundationInPurpose/DefineValuesPathway",
    summary:
      "After the mission and vision are defined, the values  are set to define how they will fulfill the company mission and vision. It describes what behaviors will be rewarded and what behaviors will not be tolerated.  The values define the culture of the company and how management will act in day-to-day business.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  AlignmentPathway: {
    url: "/user/pathway/AlignmentPathway",
    id: "AlignmentPathway",
    question: "How do I align my company with its purpose?",
    focus: "FoundationInPurpose",
    name: "Alignment",
    imgSrc: require("lib/img/pathways/AlignmentPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/FoundationInPurpose/AlignmentPathway",
    summary:
      "Your company's mission, vision, and value statements might win an award in corporate america, but if no one decides to adopt and follow them, they might as well be tossed out with the garbage.  Alignment of employees, upper management, and the mission, vision, and values of the company must be aligned.  A company can survive with out proper alignment but if it can be achieved, it is seen that a company's productivity and employee engagement increase significantly.  ",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  EthicsPathway: {
    url: "/user/pathway/EthicsPathway",
    id: "EthicsPathway",
    question: "How do I integrate ethics into my company?",
    focus: "FoundationInPurpose",
    name: "Ethics",
    imgSrc: require("lib/img/pathways/EthicsPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/FoundationInPurpose/EthicsPathway",
    summary:
      "Problems arise daily in any buisness and it is difficult to decide what decision to make when there is so much on the line.  Do we risk losing the company to help the employees?  Do I whistleblow on my supplier, knowing they may take us down with them? Should I save the company and all its employees when all I have to do is lie?  These decisions push our sense of ethical behavior, and unethical behavior can influence future decision making significantly.  This pathway gives guidance how to respond to these situation and how to navigate them.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  CustomerIdentificationPathway: {
    url: "/user/pathway/CustomerIdentificationPathway",
    id: "CustomerIdentificationPathway",
    question: "Who exactly am I serving and where are they located?",
    focus: "OperatingArena",
    name: "Customer Identification",
    imgSrc: require("lib/img/pathways/CustomerIdentificationPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/OperatingArena/CustomerIdentificationPathway",
    summary:
      "Customer Identification is a critical first step in formulating a business strategy, focusing on deeply understanding the specific demographics, lifestyles, behaviors, needs, and preferences of potential customers. This process utilizes both quantitative and qualitative data to align product development, marketing, and customer experience with the evolving needs of the target audience, enhancing market acceptance and fostering business growth.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  JobsToBeDonePathway: {
    url: "/user/pathway/JobsToBeDonePathway",
    id: "JobsToBeDonePathway",
    question: "What specific needs does my audience have?",
    focus: "OperatingArena",
    name: "Jobs to be Done",
    imgSrc: require("lib/img/pathways/JobsToBeDonePathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/OperatingArena/JobsToBeDonePathway",
    summary:
      "Understanding Jobs to be Done involves identifying the specific needs or tasks that customers seek to accomplish using a product or service, focusing on the desired outcomes rather than the product itself. This perspective dives deep into customer motivations, allowing businesses to align their offerings more closely with what customers truly value. By grasping why customers make their purchasing decisions, companies can better innovate and precisely meet their needs, discovering new market opportunities and driving customer-centric innovation for ongoing success.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  EvaluateIndustryPathway: {
    url: "/user/pathway/EvaluateIndustryPathway",
    id: "EvaluateIndustryPathway",
    question: "Who uses my product, and who buys it?",
    focus: "OperatingArena",
    name: "Evaluate Industry Focus",
    imgSrc: require("lib/img/pathways/EvaluateIndustryPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/OperatingArena/EvaluateIndustryPathway",
    summary:
      "Evaluating industry focus involves a thorough analysis of the broader industry context to identify where a business can most effectively compete. This assessment encompasses industry trends, competitive dynamics, regulatory frameworks, and technological advancements. The goal is to align the company’s strengths with market opportunities and challenges, informing strategic decisions in product development, marketing, and investments, thereby crafting a roadmap for sustainable competitive advantage.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  ChosenPositionPathway: {
    url: "/user/pathway/ChosenPositionPathway",
    id: "ChosenPositionPathway",
    question: "How does my product/service meet the specific needs of my target audience?",
    focus: "OperatingArena",
    name: "Chosen Position",
    imgSrc: require("lib/img/pathways/ChosenPositionPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/OperatingArena/ChosenPositionPathway",
    summary:
      "Choosing your market position involves defining how your brand and offerings are perceived, focusing on a unique value proposition that differentiates your business. This strategic decision influences all facets of business strategy—product development, marketing, sales, and customer service—to ensure alignment with this position. It involves understanding your strengths, customer needs, and the competitive landscape to establish a niche that leverages your unique capabilities for sustainable competitive advantage and long-term success.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  SegmentsPathway: {
    url: "/user/pathway/SegmentsPathway",
    id: "SegmentsPathway",
    question: "How do I convey my understanding of my audience's needs?",
    focus: "OperatingArena",
    name: "Segments",
    imgSrc: require("lib/img//pathways/SegmentsPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/OperatingArena/SegmentsPathway",
    summary:
      "Market segmentation involves dividing a broad target market into subsets of consumers with shared needs and interests, allowing businesses to focus resources on the most profitable segments. By understanding demographic, geographic, psychographic, and behavioral factors, companies can tailor their offerings to meet specific consumer needs, enhancing satisfaction and competitive advantage. Effective segmentation enables targeted marketing strategies, optimizes resource allocation, and ensures agility in adapting to market changes, fostering long-term growth.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  CJobsToBeDonePathway: {
    url: "/user/pathway/CJobsToBeDonePathway",
    id: "CJobsToBeDonePathway",
    question: "What is the job to be done?",
    focus: "CustomerUnmetNeeds",
    name: "Jobs to be Done",
    imgSrc: require("lib/img/pathways/CJobsToBeDonePathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/CustomerUnmetNeeds/CJobsToBeDonePathway",
    summary:
      'Customers purchase products and services to help them solve their needs. In other words, customers "hire" products and services to help them complete "jobs". Knowing what "jobs" your customers want done will explain what your products and services should accomplish.',
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  PainPointsPathway: {
    url: "/user/pathway/PainPointsPathway",
    id: "PainPointsPathway",
    question: "What are the pain points of doing this job?",
    focus: "CustomerUnmetNeeds",
    name: "Pain Points",
    imgSrc: require("lib/img/pathways/PainPointsPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/CustomerUnmetNeeds/PainPointsPathway",
    summary:
      'Because no product or service is perfect, customers always experience some level of frustration when using them. These are pain points. Businesses can uncover pain points by seeing customers engage with their current solutions as they complete their "jobs".',
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  UniqueValuePathway: {
    url: "/user/pathway/UniqueValuePathway",
    id: "UniqueValuePathway",
    question: "How can you do the job and address the pain points in a way no competitors do?",
    focus: "CustomerUnmetNeeds",
    name: "Unique Value",
    imgSrc: require("lib/img/pathways/UniqueValuePathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/CustomerUnmetNeeds/UniqueValuePathway",
    summary:
      "Once customers' jobs to be done and pain points are discovered, you understand what a product or service must achieve. Product differentiation explains how to create products and services that meet customers' needs, reduce pains, and are unique to alternatives.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  ExistingRAndCPathway: {
    url: "/user/pathway/ExistingRAndCPathway",
    id: "ExistingRAndCPathway",
    question: "What are the existing resources and capabilities?",
    focus: "UnifiedBusinessModel",
    name: "Existing Resources & Capabilities",
    imgSrc: require("lib/img/pathways/ExistingRAndCPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/UnifiedBusinessModel/ExistingRAndCPathway",
    summary:
      "To best understand how firm activities need to shift to accommodate your value proposition, you need to know how your firm currently functions. What resources and capabilities currently exist to deliver value to customers within the organization?",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  AnalyzeFirmActivitiesPathway: {
    url: "/user/pathway/AnalyzeFirmActivitiesPathway",
    id: "AnalyzeFirmActivitiesPathway",
    question: "What are the firm's activities?",
    focus: "UnifiedBusinessModel",
    name: "Analyze Firm Activities",
    imgSrc: require("lib/img/pathways/AnalyzeFirmActivitiesPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/UnifiedBusinessModel/AnalyzeFirmActivitiesPathway",
    summary:
      "Based on your targeted unique value proposition, what resources and capabilities need to be added, changed, or removed? There are many factors that could be considered here. Focus on those activities that actually provide value.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  ImplementChangesPathway: {
    url: "/user/pathway/ImplementChangesPathway",
    id: "ImplementChangesPathway",
    question: "How do I implement changes to my firm's activities?",
    focus: "UnifiedBusinessModel",
    name: "Implement Changes",
    imgSrc: require("lib/img/pathways/ImplementChangesPathway.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/UnifiedBusinessModel/ImplementChangesPathway",
    summary:
      "Once you know where your organization stands and what needs to be changed, how will these be implemented and monitored? Changes should be prioritized based on impact to your unique value add. Methods for monitoring alignment should also be considered.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  CapabilitiesAdvantagePathway: {
    url: "/user/pathway/CapabilitiesAdvantagePathway",
    id: "CapabilitiesAdvantagePathway",
    question: "What are the capabilities that give us an advantage?",
    focus: "SafeguardsAgainstImitation",
    name: "Capabilities Advantage",
    imgSrc: require("lib/img/DaVinciPillars.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/SafeguardsAgainstImitation/CapabilitiesAdvantagePathway",
    summary:
      "Capabilities advantages describe a company's internal capabilities to perform activities better than other companies.  This relates to functional skills, such as research and development, supply chain, trade secrets, expertise, learning curve, data and analytics dominance, etc.  it describes the skills that the company developed within itself.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  LocationAdvantagePathway: {
    url: "/user/pathway/LocationAdvantagePathway",
    id: "LocationAdvantagePathway",
    question: "What are the location advantages?",
    focus: "SafeguardsAgainstImitation",
    name: "Location Advantage",
    imgSrc: require("lib/img/DaVinciPillars.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/SafeguardsAgainstImitation/LocationAdvantagePathway",
    summary:
      "Location advantages refer to the benefits of where the physical building is located.  These advantages could come from the city, county, state, or country, or the proximity to key resources or culture.",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  SwitchingCostAdvantagePathway: {
    url: "/user/pathway/SwitchingCostAdvantagePathway",
    id: "SwitchingCostAdvantagePathway",
    question: "What are the switching cost advantages?",
    focus: "SafeguardsAgainstImitation",
    name: "Switching Cost Advantage",
    imgSrc: require("lib/img/DaVinciPillars.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/SafeguardsAgainstImitation/SwitchingCostAdvantagePathway",
    summary: "SUMMARY",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  TimingAdvantagePathway: {
    url: "/user/pathway/TimingAdvantagePathway",
    id: "TimingAdvantagePathway",
    question: "What are the timing advantages?",
    focus: "SafeguardsAgainstImitation",
    name: "Timing Advantage",
    imgSrc: require("lib/img/DaVinciPillars.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/SafeguardsAgainstImitation/TimingAdvantagePathway",
    summary: "SUMMARY",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  ManufacturingAdvantagePathway: {
    url: "/user/pathway/ManufacturingAdvantagePathway",
    id: "ManufacturingAdvantagePathway",
    question: "What are the manufacturing advantages?",
    focus: "SafeguardsAgainstImitation",
    name: "Manufacturing Advantage",
    imgSrc: require("lib/img/DaVinciPillars.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/SafeguardsAgainstImitation/ManufacturingAdvantagePathway",
    summary: "SUMMARY",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  ResourceAdvantagePathway: {
    url: "/user/pathway/ResourceAdvantagePathway",
    id: "ResourceAdvantagePathway",
    question: "What are the resource advantages?",
    focus: "SafeguardsAgainstImitation",
    name: "Resource Advantage",
    imgSrc: require("lib/img/DaVinciPillars.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/SafeguardsAgainstImitation/ResourceAdvantagePathway",
    summary: "SUMMARY",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  NetworkAdvantagePathway: {
    url: "/user/pathway/NetworkAdvantagePathway",
    id: "NetworkAdvantagePathway",
    question: "What are the network advantages?",
    focus: "SafeguardsAgainstImitation",
    name: "Network Advantage",
    imgSrc: require("lib/img/DaVinciPillars.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/SafeguardsAgainstImitation/NetworkAdvantagePathway",
    summary: "SUMMARY",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
  CustomerAdvantagePathway: {
    url: "/user/pathway/CustomerAdvantagePathway",
    id: "CustomerAdvantagePathway",
    question: "What are the customer advantages?",
    focus: "SafeguardsAgainstImitation",
    name: "Customer Advantage",
    imgSrc: require("lib/img/DaVinciPillars.jpg"),
    type: "Pathway",
    filePath: "components/pathways/pages/SafeguardsAgainstImitation/CustomerAdvantagePathway",
    summary: "SUMMARY",
    concepts: ["Cage", "EntryModes", "ConsumptionChain"],
  },
} as const satisfies TPathways;

export default Pathways as TPathways;

import { TSetNewEmailReq } from "@sharedTypes/req.type";
import { TErrorRes, TSetNewEmailRes } from "@sharedTypes/res.type";
import constants from "Constants";
import missingParametersError from "api/Error";
import axios from "axios";

export async function SetNewEmail(otp: string, oldEmail: string, newEmail: string) {
  const apiUrl = constants.apiUrl + "/email/setnewemail";

  const body: TSetNewEmailReq = {
    otp,
    oldEmail,
    newEmail,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TSetNewEmailRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}

import { TCategories, TConcept, TConcepts, TPathways, TFocuses } from "types/JsonType";
import Focuses from "./Focuses";
import Pathways from "./Pathways";

export type TConceptView = TConcept & {
  pathwayId?: string;
  color?: string;
};

export type TCodexView = {
  id: string;
  name: string;
  concepts: TConceptView[];
  url?: string;
  focus?: string;
  color?: string;
};

export function GetCodexCategories(Categories: TCategories, Concepts: TConcepts) {
  let CodexCategories: TCodexView[] = [];
  for (let categoryId in Categories) {
    const category = Categories[categoryId];
    CodexCategories.push({
      id: categoryId,
      name: category.name,
      concepts: [],
    });
    for (let conceptId in Concepts) {
      if (Concepts[conceptId].category === categoryId) {
        CodexCategories[CodexCategories.length - 1].concepts.push(Concepts[conceptId]);
      }

      CodexCategories[CodexCategories.length - 1].concepts.forEach((concept) => {
        Object.keys(Pathways).forEach((pathwayId) => {
          if (Pathways[pathwayId].concepts.includes(concept.id as any)) {
            concept.color = Focuses[Pathways[pathwayId].focus].color;
          }
        });
      });
    }
  }
  CodexCategories = CodexCategories.filter((category) => category.concepts.length > 0);

  return CodexCategories;
}

export function GetCodexPathways(Pathways: TPathways, Concepts: TConcepts) {
  let CodexPathways: TCodexView[] = [];
  for (let pathwayId in Pathways) {
    const pathway = Pathways[pathwayId];
    CodexPathways.push({
      id: pathwayId,
      name: pathway.name,
      url: pathway.url,
      focus: pathway.focus,
      color: Focuses[pathway.focus].color,
      concepts: [],
    });
    for (let i = 0; i < pathway.concepts.length; i++) {
      const conceptId = pathway.concepts[i];
      if (Concepts[conceptId]) {
        CodexPathways[CodexPathways.length - 1].concepts.push(Concepts[conceptId]);
      }
      CodexPathways[CodexPathways.length - 1].concepts.forEach((concept) => {
        concept.pathwayId = pathwayId;
      });
    }
  }
  CodexPathways = CodexPathways.filter((pathway) => pathway.concepts.length > 0);
  return CodexPathways;
}

export function GetCodexFocuses(Focuses: TFocuses, Pathways: TPathways, Concepts: TConcepts) {
  let CodexFocuses: TCodexView[] = [];
  Object.values(Focuses).forEach((focus) => {
    CodexFocuses.push({
      id: focus.id,
      name: `${focus.letter} - ${focus.name}`,
      url: focus.url,
      color: focus.color,
      concepts: [],
    });
    const CodexPathways = GetCodexPathways(Pathways, Concepts);
    for (let i = 0; i < CodexPathways.length; i++) {
      const pathway = CodexPathways[i];
      if (pathway.focus === focus.id) {
        CodexFocuses[CodexFocuses.length - 1].concepts.push(...pathway.concepts);
      }
      CodexPathways[CodexPathways.length - 1].concepts.forEach((concept) => {
        concept.pathwayId = pathway.id;
      });
    }
  });

  CodexFocuses = CodexFocuses.filter((focus) => focus.concepts.length > 0);

  return CodexFocuses;
}

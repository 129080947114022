import { Link } from "react-router-dom";
import RightArrow from "../bootstrap_icons/RightArrow";

export default function NavArrows({
  leftPath,
  rightPath,
}: {
  leftPath?: string;
  rightPath?: string;
}) {
  return (
    <div className="bg-body px-2 pt-1 pb-2 round-bottom-start border fs-8 fw-bold">
      <div className="d-flex align-items-center">
        <Link
          to={leftPath ?? ""}
          className="text-body me-2"
          style={{ visibility: !leftPath ? "hidden" : "visible" }}
        >
          <div style={{ scale: "-1 1" }}>
            <RightArrow />
          </div>
        </Link>
        <Link
          to={rightPath ?? ""}
          className="text-body"
          style={{ visibility: !rightPath ? "hidden" : "visible" }}
        >
          <RightArrow />
        </Link>
      </div>
    </div>
  );
}

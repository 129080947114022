import Definition from "../templates/Definition";
import Image from "../templates/Image";
const ExperienceCurveImg = require("lib/img/concepts/ExperienceCurve.png");

export default function ExperienceCurve() {
  return (
    <div>
      <Definition>
        <p>
          What should we expect from our costs over time? What should we expect our competitors to
          experience with their costs going forward? The experience curve can give us insight into a
          firm''s costs, both within a firm and looking at primary competitors. This is illustrated
          by identifying a relationship between a firm''s cumulative experience and its costs. The
          experience curve is NOT to be mistaken for a scale curve, it has nothing to do
        </p>
        <div>In order to construct an experience curve, a firm must have:</div>
        <ol>
          <li>A clearly defined unit of experience</li>
          <li>Cumulative annual unit production volume over time</li>
          <li>Constant currency unit costs per unit over that same time period</li>
        </ol>
        <p>
          This information is then plotted such that experience is plotted on the x-axis and cost is
          plotted on the y-axis. The result will be a downward-sloping curve that demonstrates the
          relationship between your cumulative experience and costs. The characteristic relationship
          is that costs decline 20-30% with each doubling of unit volume.
        </p>
        <p>
          This tool will allow a firm to more effectively and accurately predict their future costs,
          as well as predict the actions of their competitors due to constantly decreasing costs.
        </p>
        <p>
          Example - The Ford Model T was one of the first products that was scaled production to a
          level that reflects this curve in a similar way to today''s major manufacturers. The
          application of the assembly line allowed them to build quickly and rapidly scale their
          production. Ford was able to recognize a 75% cost reduction from 1920 to 1931 in the
          production of the Model T.
        </p>
      </Definition>
      <Image src={ExperienceCurveImg} alt="" />
      <p>
        An experience curve is a downward-sloping curve reflecting the relationship between
        accumulated experience in an industry and the long-term decline in costs and prices. This
        curve mathematically expresses the intuitive idea that the more a task is repeated, the
        lower its cost should be. It holds predictive power for managers who apply it correctly,
        allowing them to anticipate future costs, compare cost trends with competitors, and make
        informed decisions based on the stability of prices in their industry.
      </p>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}

import { TChatCompletionMessageParam, TChatBotReq } from "@sharedTypes/req.type";
import { TChatBotRes, TErrorRes } from "@sharedTypes/res.type";
import missingParametersError from "api/Error";
import axios from "axios";
import constants from "Constants";

export default async function ChatBot(messages: TChatCompletionMessageParam[]) {
  const apiUrl = constants.apiUrl + "/tools/chatbot/chatbot";

  const body: TChatBotReq = {
    userId: localStorage.getItem("userId"),
    token: localStorage.getItem("token"),
    messages: messages,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TChatBotRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}

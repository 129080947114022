import { TGetWebUsageSummaryReq } from "@sharedTypes/req.type";
import { TGetWebUsageSummaryRes, TErrorRes } from "@sharedTypes/res.type";
import constants from "Constants";
import axios from "axios";

export async function GetWebUsageSummary() {
  const apiUrl = constants.apiUrl + "/webusage/GetWebUsageSummary";

  const body: TGetWebUsageSummaryReq = {
    userId: localStorage.getItem("userId"),
    token: localStorage.getItem("token"),
    timeZone: new Date().getTimezoneOffset(),
  };

  const data: TGetWebUsageSummaryRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}

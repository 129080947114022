import Definition from "components/concepts/templates/Definition";
import Image from "components/concepts/templates/Image";

export default function EntryModes() {
  return (
    <div>
      <Definition>
        <p>
          When businesses are considering international expansion, it is not always clear which
          avenue aligns with corporate objectives. Understanding the various entry modes (as well as
          their pros and cons) can assist business leaders as they identify which strategy is
          optimal for their situation.
        </p>
        <ol>
          <li>
            <b>Exporting</b> - The process of producing a good or service in one country and selling
            it to another.
          </li>
          <ol type="a">
            <li>
              Ex. Apple selling iPhones directly to consumers in China through its online store and
              authorized retailers.
            </li>
          </ol>
          <li>
            <b>Turnkey Operations</b> - A company builds a foreign facility while a client owns and
            operates it.
          </li>
          <ol type="a">
            <li>
              Ex. A construction company builds a power plant in Africa, trains local personnel, and
              then hands over ownership and operations to a local government agency.
            </li>
          </ol>
          <li>
            <b>Licensing</b> - A contractual agreement granting the licensee certain rights such as
            producing or selling the product, displaying brand names, or using licensor’s IP.
          </li>
          <ol type="a">
            <li>
              Ex. Coca-Cola grants a bottling company in India the right to produce and sell Coke
              products using the Coca-Cola brand and recipe.
            </li>
          </ol>
          <li>
            <b>Franchising</b> - Similar to licensing, but the franchiser maintains an active role
            in the ongoing operations.
          </li>
          <ol type="a">
            <li>
              Ex. McDonald's grants franchise rights to an entrepreneur in France, who opens and
              operates a McDonald's restaurant following company standards and procedures.
            </li>
          </ol>
          <li>
            <b>Joint Ventures</b> - A new legal entity created and owned by multiple companies in
            different countries.
          </li>
          <ol type="a">
            <li>
              Ex. BMW partners with a Chinese automaker to establish a new car manufacturing plant
              in China, sharing ownership, profits, and management responsibilities.
            </li>
          </ol>
          <li>
            <b>Wholly Owned Subsidiary</b> - A parent company sets ups a new legal entity that is
            legally separate but that it fully owns.
          </li>
          <ol type="a">
            <li>
              Ex. Amazon establishes a fully owned subsidiary in Germany to build its own
              fulfillment centers and deliver products directly to customers.
            </li>
          </ol>
        </ol>
        <p>
          By understanding these entry modes you can correctly determine the amount of control and
          risk you would like to undertake.
        </p>
      </Definition>
      <Image
        src={require("lib/img/concepts/EntryModes.png")}
        alt="chart comparing and contrasting different entry modes"
      />
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}

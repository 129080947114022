import {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { SaveP5F } from "api/tools/p5f/SaveP5F";
import { DeleteP5F } from "api/tools/p5f/DeleteP5F";
import { GetP5F } from "api/tools/p5f/GetP5F";
import { TPortersFiveForcesDb } from "@sharedTypes/db.type";
import UserContext from "context/UserContext";
import { CircularSliderWithChildren } from "react-circular-slider-svg";
import constants from "Constants";
import useElementSize from "hooks/useElementSize";
import { Concepts } from "lib/data/Concepts";

const CollapseIcon = require("lib/img/icons/back-arrow.png");

type TPosition = {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
};
export function useElementPosition<T extends HTMLElement = HTMLDivElement>(): [
  MutableRefObject<T | null>,
  TPosition,
  (ref: T) => void,
] {
  const [position, setPosition] = useState<TPosition>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const ref = useRef<T | null>(null);

  const updatePosition = (ref: T) => {
    setPosition(ref.getBoundingClientRect());
  };
  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      updatePosition(currentRef);
    }
    let eventListener =
      currentRef &&
      window.addEventListener("scroll", () => {
        updatePosition(currentRef);
      });

    return () => {
      eventListener && window.removeEventListener("scroll", eventListener);
    };
  }, []);

  return [ref, position, updatePosition];
}

type TP5fContext = {
  userUpdateValues: TUserUpdateValues;
  setUserUpdateValues: React.Dispatch<React.SetStateAction<TUserUpdateValues>>;
  GetSavedAnalytics: () => void;
  presavedAnalytics: TPresets[];
  calculatedValues: {
    score: number;
    weightPerc: number;
  }[];
};

const P5fContext = createContext<TP5fContext>({
  userUpdateValues: {} as any,
  setUserUpdateValues: () => { },
  GetSavedAnalytics: () => { },
  presavedAnalytics: [],
  calculatedValues: [],
});

type TPresets = {
  creationDate: string;
  userId: number;
  saveId: number;
  saveName: string;
  forcesData: TUserUpdateValues;
};

type TUserUpdateValues = {
  title: string;
  weight: number;
  sections: {
    title: string;
    weight: number;
    questions: {
      title: string;
      value: number;
      weight: number;
      options: string[];
    }[];
  }[];
}[];

type TQuestion = {
  title: string;
  value: number;
  weight: number;
  options: string[];
};

type TSection = {
  title: string;
  weight: number;
  questions: TQuestion[];
};

type TForce = {
  title: string;
  weight: number;
  sections: TSection[];
};

type TMessage = {
  type: string;
  message: string;
};

function PresavedList() {
  const { presavedAnalytics, setUserUpdateValues, GetSavedAnalytics } =
    useContext(P5fContext);

  if (!presavedAnalytics || presavedAnalytics.length === 0) {
    return null;
  }

  async function DeletePresetHandler(saveId: number) {
    if (!window.confirm("Are you sure you want to delete this analysis?"))
      return;

    const data = await DeleteP5F(saveId);

    if (data.status === "success") {
      GetSavedAnalytics();
    } else {
      console.error(data);
    }
  }

  function LoadPresavedDataHandler(forcesData: any) {
    setUserUpdateValues(forcesData);
  }

  return (
    <>
      <div className="py-2 d-flex justify-content-between align-items-center">
        <div>
          <h3 className="mb-0">Load Presaved Analysis </h3>
          <div className="text-secondary">Load a previously saved analysis</div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Save Name</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {presavedAnalytics.map((saveData, i) => {
            return (
              <tr key={i}>
                <td className="w-100 align-middle">{saveData.saveName}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => LoadPresavedDataHandler(saveData.forcesData)}
                    style={{ width: 80 }}
                  >
                    Load
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      DeletePresetHandler(saveData.saveId);
                    }}
                    style={{ width: 80 }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function SaveInput({
  setMessage,
}: {
  setMessage: React.Dispatch<React.SetStateAction<TMessage | null>>;
}) {
  const { userUpdateValues, GetSavedAnalytics, presavedAnalytics } =
    useContext(P5fContext);

  function ChangeMessage(type: string, newMessage: string) {
    setMessage({ type: type, message: newMessage });
  }

  async function SaveAnalyticsSubmitHandler(e: any) {
    e.preventDefault();

    const saveName = e.target["save-name"].value;

    if (!saveName) {
      ChangeMessage("danger", "You must enter a name");
      return;
    }

    if (presavedAnalytics.length >= 5) {
      ChangeMessage("danger", "You can only save 5 analytics");
      return;
    }

    const data = await SaveP5F(saveName, userUpdateValues);

    if (data.status === "success") {
      ChangeMessage("success", "Analytics saved");
      GetSavedAnalytics();
    } else {
      ChangeMessage("danger", "Error saving analytics");
      console.error(data);
    }

    e.target.reset();
  }

  return (
    <form onSubmit={SaveAnalyticsSubmitHandler}>
      <input
        name="save-name"
        className="form-control"
        type="text"
        placeholder="Save Name"
      />
      <button className="btn btn-primary my-2" type="submit">
        Save Current Analysis
      </button>
    </form>
  );
}

function SavedAnalyticsAlerts({
  message,
  setMessage,
}: {
  message: TMessage | null;
  setMessage: React.Dispatch<React.SetStateAction<TMessage | null>>;
}) {
  if (!message) {
    return null;
  }

  return (
    <div id="message-box" className="my-4 position-relative ">
      <div className={"alert alert-" + message?.type}>
        <div className="mt-2">
          <h4>{message?.message}</h4>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-2"
            aria-label="Close"
            onClick={() => setMessage(null)}
          ></button>
        </div>
      </div>
    </div>
  );
}

function AnalyticsSaver() {
  const [message, setMessage] = useState<TMessage | null>(null);
  const isLoggedIn = useContext(UserContext).loginStatus === "logged in";

  return (
    <div
      id="save-analytics"
      className=" px-2 border border-2 rounded mb-2 position-relative "
    >
      <div
        id="save-analytics-anchor"
        className="position-absolute"
        style={{ top: -70 }}
      ></div>
      <div className="px-2 py-3">
        <h3 className="mb-0">Save Current Analysis </h3>
        <div className="text-secondary pb-3">
          Industry analysis takes a lot of work! Don't let it go to waste. Give
          your analysis a name, and we'll save it for you so you don't have to
          start from scratch next time you're here.
        </div>
        {isLoggedIn ? (
          <SaveInput setMessage={setMessage} />
        ) : (
          <Link
            className="btn btn-primary"
            to={"/login/?return_url=" + window.location.pathname}
          >
            Log In to Save Analysis
          </Link>
        )}
      </div>

      <SavedAnalyticsAlerts message={message} setMessage={setMessage} />
    </div>
  );
}

function OptionButton({
  userUpdateValues,
  setUserUpdateValues,
  forceIndex,
  sectionIndex,
  questionIndex,
  option,
  optionIndex,
}: {
  userUpdateValues: TForce[];
  setUserUpdateValues: React.Dispatch<React.SetStateAction<TForce[]>>;
  forceIndex: number;
  sectionIndex: number;
  questionIndex: number;
  option: string;
  optionIndex: number;
}) {
  const optionSelectHandler = (e: any) => {
    let newValues = [...userUpdateValues];
    newValues[forceIndex].sections[sectionIndex].questions[
      questionIndex
    ].value = optionIndex + 1;
    setUserUpdateValues(newValues);
  };

  const isActive =
    userUpdateValues[forceIndex].sections[sectionIndex].questions[questionIndex]
      .value ===
    optionIndex + 1;

  return (
    <button
      className={`btn bg-white m-0 rounded-0 p-1 p-sm-2 ${isActive ? "active" : "border"}`}
      style={{ flexBasis: "20%", borderColor: constants.colors.greyRed }}
      onClick={optionSelectHandler}
    >
      {option}
    </button>
  );
}

function Question({
  userUpdateValues,
  setUserUpdateValues,
  forceIndex,
  sectionIndex,
  question,
  questionIndex,
}: {
  userUpdateValues: TForce[];
  setUserUpdateValues: React.Dispatch<React.SetStateAction<TForce[]>>;
  forceIndex: number;
  sectionIndex: number;
  question: TQuestion;
  questionIndex: number;
}) {
  return (
    <div className="w-100">
      <div className="py-2 ">
        <div className="mb-2 fs-5">{question.title}</div>

        <div className="d-flex">
          {question.options.map((option: string, optionIndex: number) => {
            return (
              <OptionButton
                key={optionIndex}
                userUpdateValues={userUpdateValues}
                setUserUpdateValues={setUserUpdateValues}
                forceIndex={forceIndex}
                sectionIndex={sectionIndex}
                questionIndex={questionIndex}
                option={option}
                optionIndex={optionIndex}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Section({
  forceIndex,
  section,
  sectionIndex,
  forceSectionTotalWeight,
  headerHeight,
}: {
  forceIndex: number;
  section: TSection;
  sectionIndex: number;
  forceSectionTotalWeight: number;
  headerHeight: number;
}) {
  const { userUpdateValues, setUserUpdateValues } = useContext(P5fContext);
  const sectionQuestionWeightTotal = section.questions.reduce(
    (acc, question) => (acc += question.weight),
    0,
  );

  const sectionScore = section.questions.reduce(
    (acc, question) =>
      (acc += question.value * (question.weight / sectionQuestionWeightTotal)),
    0,
  );

  return (
    <div className="position-relative">
      <div
        className=" border  d-flex align-items-center justify-content-between bg-tan-2 position-sticky py-2 px-4"
        style={{ top: constants.navHeight + headerHeight }}
      >
        <div className="fs-4 open-sans">{section.title}</div>
        <div className="text-end">
          Score: {Math.round(sectionScore * 100) / 100} - Weight:{" "}
          {Math.round((section.weight / forceSectionTotalWeight) * 100) || 0}%{" "}
        </div>
      </div>

      <div className="py-4 px-2">
        {section.questions.map((question, questionindex) => {
          return (
            <Question
              key={questionindex}
              userUpdateValues={userUpdateValues}
              setUserUpdateValues={setUserUpdateValues}
              forceIndex={forceIndex}
              sectionIndex={sectionIndex}
              question={question}
              questionIndex={questionindex}
            />
          );
        })}
      </div>
    </div>
  );
}

function Force({
  force,
  forceIndex,
  setForcePositions,
}: {
  force: TForce;
  forceIndex: number;
  setForcePositions: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const [collapsed, setCollapsed] = useState(false);
  const forceSectionTotalWeight = force.sections.reduce(
    (acc, section) => (acc += section.weight),
    0,
  );

  const [headerRef, { height }] = useElementSize();

  const forceRef = useRef<HTMLDivElement>(null);

  const [forceScrollRef, { y }] = useElementPosition();

  useEffect(() => {
    setForcePositions((forcePositions) => {
      const newForcePositions = forcePositions.slice();
      newForcePositions[forceIndex] = y;
      return newForcePositions;
    });
  }, [y, setForcePositions, forceIndex]);

  return (
    <div className="position-relative" ref={forceRef}>
      <div
        id={`force-${forceIndex}`}
        className="position-relative"
        style={{ top: -constants.navHeight }}
      ></div>

      <Header
        forceScrollRef={forceScrollRef}
        force={force}
        forceIndex={forceIndex}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        headerRef={headerRef}
      />

      <div className={collapsed ? "d-none" : ""}>
        {force.sections.map((section, sectionIndex) => {
          return (
            <Section
              key={sectionIndex}
              forceIndex={forceIndex}
              section={section}
              sectionIndex={sectionIndex}
              forceSectionTotalWeight={forceSectionTotalWeight}
              headerHeight={height}
            />
          );
        })}
        <div className="position-relative">
          <div
            className="d-flex align-items-center justify-content-between bg-tan-2 border position-sticky z-1 py-2 px-4"
            style={{ top: constants.navHeight + height }}
          >
            <div className="fs-4 open-sans">Relative Section Weights</div>
          </div>
          <div className="container-fluid pb-4 w-100">
            <div className="row justify-content-center ">
              {force.sections.map((section, sectionIndex) => (
                <div className="col-6 col-md-4" key={sectionIndex}>
                  <SectionWeightSlider
                    sectionIndex={sectionIndex}
                    forceIndex={forceIndex}
                    forceSectionTotalWeight={forceSectionTotalWeight}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SectionWeightSlider({
  sectionIndex,
  forceIndex,
  forceSectionTotalWeight,
}: {
  sectionIndex: number;
  forceIndex: number;
  forceSectionTotalWeight: number;
}) {
  const { userUpdateValues, setUserUpdateValues } = useContext(P5fContext);
  const force = userUpdateValues[forceIndex];
  const section = force.sections[sectionIndex];
  const [sliderValue, setSliderValue] = useState(section.weight - 100);

  function WeightChangeHandler(sliderValue: number) {
    setSliderValue(sliderValue);
    let newValues = [...userUpdateValues];
    newValues[forceIndex].sections[sectionIndex].weight = sliderValue + 100;
    setUserUpdateValues(newValues);
  }

  const arcColorOpacity = Math.round((sliderValue + 100) * (255 / 200));

  return (
    <div className="pt-4 h-100">
      <div className="d-flex flex-column align-items-center justify-content-between h-100 rounded px-2 py-3 bg-white border">
        <div className="text-center fs-4">{section.title}</div>
        <div className="d-flex flex-column align-items-center justify-content-center ">
          <CircularSliderWithChildren
            handle1={{
              value: sliderValue,
              onChange: WeightChangeHandler,
            }}
            arcColor={
              constants.colors.greyRed +
              arcColorOpacity.toString(16).padStart(2, "0")
            }
            startAngle={40}
            endAngle={320}
            minValue={-100}
            maxValue={100}
            size={180}
          >
            <div
              className="position-absolute overflow-visible d-flex flex-column justify-content-center align-items-center no-word-wrap"
              style={{ top: 45, width: 0, height: 0 }}
            >
              <div className="open-sans fs-1 lh-1 ">
                {sliderValue > 0 && "+"}
                {Math.round(sliderValue)}
              </div>
            </div>
          </CircularSliderWithChildren>

          <div className="text-center">
            Accounts for{" "}
            {Math.round((section.weight / forceSectionTotalWeight) * 100) || 0}%
            of {force.title} Score
          </div>
        </div>
      </div>
    </div>
  );
}

function ForceWeightSlider({ forceIndex }: { forceIndex: number }) {
  const { userUpdateValues, setUserUpdateValues, calculatedValues } =
    useContext(P5fContext);
  const forceCalculatedValues = calculatedValues[forceIndex];
  const force = userUpdateValues[forceIndex];
  const [sliderValue, setSliderValue] = useState(force.weight - 100);

  function WeightChangeHandler(sliderValue: number) {
    setSliderValue(sliderValue);
    let newValues = [...userUpdateValues];
    newValues[forceIndex].weight = sliderValue + 100;
    setUserUpdateValues(newValues);
  }

  const arcColorOpacity = Math.round((sliderValue + 100) * (255 / 200));

  return (
    <div className="pt-5 h-100">
      <div className="d-flex flex-column align-items-center h-100 justify-content-between rounded px-2 py-3 bg-tan-2">
        <h2 className="text-center">{force.title}</h2>
        <div className="d-flex flex-column align-items-center">
          <CircularSliderWithChildren
            handle1={{ value: sliderValue, onChange: WeightChangeHandler }}
            arcColor={
              constants.colors.greyRed +
              arcColorOpacity.toString(16).padStart(2, "0")
            }
            startAngle={40}
            endAngle={320}
            minValue={-100}
            maxValue={100}
            trackWidth={13}
          >
            <div
              className="position-absolute overflow-visible d-flex flex-column justify-content-center align-items-center no-word-wrap"
              style={{ top: 45, width: 0, height: 0 }}
            >
              <div className="open-sans fs-1 lh-1 ">
                {sliderValue > 0 && "+"}
                {Math.round(sliderValue)}
              </div>
            </div>
          </CircularSliderWithChildren>
          <div className="text-center fs-5">
            Accounts for {Math.round(forceCalculatedValues.weightPerc) || 0}% of
            Industry Score
          </div>
        </div>
      </div>
    </div>
  );
}

function ForcesWeights() {
  const { userUpdateValues } = useContext(P5fContext);

  return (
    <div className="px-3">
      <div className="display-5 text-center mt-5 ">
        Set Relative Force Weights
      </div>

      <div className="container mb-5">
        <div className="row justify-content-center ">
          {userUpdateValues.map((force, forceIndex) => (
            <div className="col-12 col-sm-6 col-md-4" key={forceIndex}>
              <ForceWeightSlider forceIndex={forceIndex} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Summary({
  calculatedValues,
}: {
  calculatedValues: {
    score: number;
    weightPerc: number;
  }[];
}) {
  let industryScore = calculatedValues.reduce((acc, force) => {
    return (acc += force.score * (force.weightPerc / 100));
  }, 0);

  const forces = [
    "Rivalry",
    "Buyer Power",
    "Supplier Power",
    "New Entrants",
    "Substitutes",
  ];
  return (
    <div className="mt-5">
      <div>
        <div className="text-center display-3">Force Analysis Summary</div>
        <hr />
      </div>
      <div>
        <table className="table ">
          <thead>
            <tr>
              <th>Force</th>
              <th>Value (1 Low - 5 High)</th>
              <th style={{ minWidth: 75 }}>Weight</th>
            </tr>
          </thead>
          <tbody>
            {calculatedValues.map((force, index) => {
              return (
                <tr key={"Summary - " + index}>
                  <td>{forces[index]}</td>
                  <td>{Math.round(force.score * 100) / 100}</td>
                  <td>{Math.round(force.weightPerc * 10) / 10}%</td>
                </tr>
              );
            })}
            <tr className="table-warning">
              <td>Industry Score</td>
              <td>{Math.round(industryScore * 100) / 100}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function Header({
  forceScrollRef,
  force,
  forceIndex,
  collapsed,
  setCollapsed,
  headerRef,
}: {
  forceScrollRef: React.MutableRefObject<HTMLDivElement | null>;
  force: TForce;
  forceIndex: number;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  headerRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
  const { calculatedValues } = useContext(P5fContext);
  const forceCalculatedValues = calculatedValues[forceIndex];

  return (
    <div
      className="position-sticky z-2 bg-dark-tan text-light "
      style={{ top: constants.navHeight }}
      ref={headerRef}
    >
      <div
        ref={forceScrollRef}
        className="position-relative d-flex justify-content-between align-items-center py-2 px-4"
      >
        <div className="fs-2 open-sans">
          Force {forceIndex + 1} - {force.title}
        </div>
        <div className="d-flex align-items-center">
          <div className="text-end">
            Score: {Math.round(forceCalculatedValues.score * 100) / 100 || 0} -
            Weight: {Math.round(forceCalculatedValues.weightPerc) || 0}%{" "}
          </div>

          <button
            className="btn p-0 ms-3"
            style={{
              transform: collapsed ? "rotate(270deg)" : "rotate(90deg)",
              transition: "transform 0.5s",
              width: 30,
              height: 30,
            }}
            onClick={() => setCollapsed((collapsed) => !collapsed)}
          >
            <img
              src={CollapseIcon}
              height={30}
              width={30}
              alt=""
              style={{
                transform: "translate(-1px, -1px)",
                padding: 5,
              }}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

function CalculatorInput() {
  const { userUpdateValues } = useContext(P5fContext);

  const [forcePositions, setForcePositions] = useState<number[]>([]);

  const forceInView = forcePositions.findIndex(
    (forcePosition) => forcePosition > 0,
  );

  return (
    <div className="position-relative">
      <ForcesWeights />
      <div className="d-flex position-relative">
        <div>
          {userUpdateValues.map((force, forceIndex) => {
            return (
              <Force
                key={forceIndex}
                force={force}
                forceIndex={forceIndex}
                setForcePositions={setForcePositions}
              />
            );
          })}
        </div>
        <div className="position-relative ">
          <div
            className="position-sticky  ps-3 d-flex flex-column justify-content-center text-no-wrap"
            style={{
              top: (window.innerHeight - constants.navHeight - 248) / 2,
            }}
          >
            {userUpdateValues.map((force, i) => {
              const active = forceInView === i;
              return (
                <div key={i}>
                  <button
                    className={`btn py-0 px-1 cursor-pointer underline-on-hover ${active ? "text-primary" : ""
                      }`}
                    onClick={() =>
                      document.getElementById(`force-${i}`)?.scrollIntoView()
                    }
                  >
                    {i + 1}
                  </button>
                  <div className="text-center">
                    {i !== userUpdateValues.length - 1 && "|"}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function AdvancedCalculator() {
  const userUpdateValuesDefaultLists = [
    {
      title: "Rivalry",
      weight: 100,
      sections: [
        {
          title: "Number of Competitors",
          weight: 100,
          questions: [
            {
              title: "Top 4 competitors' total market share",
              value: 0,
              weight: 100,
              options: ["40%", "40-50%", "50-60%", "60-70%", ">70%"],
            },
          ],
        },
        {
          title: "Degree of Industry Standardization",
          weight: 100,
          questions: [
            {
              title: "Percent of industry standardized",
              value: 0,
              weight: 100,
              options: ["<2%", "2-5%", "5-10%", "10-15%", ">15%"],
            },
            {
              title: "What percent of industry products are sold at discount",
              value: 0,
              weight: 100,
              options: ["100%", "75%", "50%", "25%", "0%"],
            },
            {
              title: "Customer ability to recognize industry brands",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Medium", "-", "High"],
            },
            {
              title: "Buyer switching costs",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Medium", "-", "High"],
            },
          ],
        },
        {
          title: "Industry Growth Rate",
          weight: 100,
          questions: [
            {
              title: "Industry growth rate percentage",
              value: 0,
              weight: 100,
              options: ["0%", "0-1%", "1-3%", "3-5%", ">5%"],
            },
          ],
        },
        {
          title: "Industry Production Capacity",
          weight: 100,
          questions: [
            {
              title: "Industry production capacity percentage",
              value: 0,
              weight: 100,
              options: ["<70%", "70-80%", "80-90%", "90-100%", ">100%"],
            },
          ],
        },
        {
          title: "Fixed Costs",
          weight: 100,
          questions: [
            {
              title:
                "Degree to which firms have high fixed costs, high storage costs, or perishable products",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Medium", "-", "High"],
            },
          ],
        },
        {
          title: "Exit Barriers",
          weight: 100,
          questions: [
            {
              title: "Extent of exit barriers",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Medium", "-", "High"],
            },
          ],
        },
      ],
    },
    {
      title: "Power of Buyers",
      weight: 100,
      sections: [
        {
          title: "Buyer Industry Bargaining Power",
          weight: 100,
          questions: [
            {
              title: "Buyer concentration",
              value: 0,
              weight: 100,
              options: [">70%", "60-70%", "50-60%", "40-50%", "<40%"],
            },
            {
              title: "Buyer switching costs",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Medium", "-", "High"],
            },
            {
              title: "Demand",
              value: 0,
              weight: 100,
              options: ["<0%", "0-1%", "1-3%", "3-5%", ">5%"],
            },
            {
              title: "Buyer backward integration",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Medium", "-", "High"],
            },
          ],
        },
        {
          title: "Buyer Industry Price Sensitivity",
          weight: 100,
          questions: [
            {
              title: "Profitability of average buyer",
              value: 0,
              weight: 100,
              options: [">70%", "60-70%", "50-60%", "40-50%", "<40%"],
            },
            {
              title: "Percent of incumbent products sold at discount",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Medium", "-", "High"],
            },
            {
              title:
                "Cost of incumbent's product as a percent of buyer's total costs",
              value: 0,
              weight: 100,
              options: ["<0%", "0-1%", "1-3%", "3-5%", ">5%"],
            },
            {
              title: "Number of high volume purchases",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Medium", "-", "High"],
            },
            {
              title: "Impact of incumbents product on buyer's product quality",
              value: 0,
              weight: 100,
              options: ["<0%", "0-1%", "1-3%", "3-5%", ">5%"],
            },
            {
              title: "Degree to which incumbent's product saves buyer money",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Medium", "-", "High"],
            },
          ],
        },
      ],
    },
    {
      title: "Power of Suppliers",
      weight: 100,
      sections: [
        {
          title: "Number and Size of Suppliers",
          weight: 100,
          questions: [
            {
              title: "Top 4 suppliers' total market share",
              value: 0,
              weight: 100,
              options: [">70%", "60-70%", "50-60%", "40-50%", "<40%"],
            },
          ],
        },
        {
          title: "Difficulty of Switching Suppliers",
          weight: 100,
          questions: [
            {
              title: "Percent of total costs supplied by supplier",
              value: 0,
              weight: 100,
              options: [">30%", "20-30%", "10-20%", "5-10%", "<5%"],
            },
            {
              title: "Differences in price between suppliers",
              value: 0,
              weight: 100,
              options: [">15%", "10-15%", "5-10%", "2-5%", "<2%"],
            },
            {
              title: "Importance of brands",
              value: 0,
              weight: 100,
              options: ["High", "-", "Medium", "-", "Low"],
            },
          ],
        },
        {
          title: "Possibility of Supplier Forward Integration",
          weight: 100,
          questions: [
            {
              title: "Forward integration",
              value: 0,
              weight: 100,
              options: ["High", "-", "Medium", "-", "Low"],
            },
          ],
        },
      ],
    },
    {
      title: "Threat of New Entrants",
      weight: 100,
      sections: [
        {
          title: "Incumbent Firm Cost Advantages",
          weight: 100,
          questions: [
            {
              title: "Size of scale economies (slope of scale curve)",
              value: 0,
              weight: 100,
              options: [">95%", "85-95%", "75-85%", "65-75%", "<65%"],
            },
            {
              title: "Size of investment in plant and equipment",
              value: 0,
              weight: 100,
              options: ["Small", "-", "Med", "-", "Large"],
            },
            {
              title: "Marketing and advertising expenditures",
              value: 0,
              weight: 100,
              options: ["Small", "-", "Med", "-", "Large"],
            },
            {
              title: "Market share protection",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Med", "-", "High"],
            },
            {
              title: "Required market share to break even",
              value: 0,
              weight: 100,
              options: ["<5%", "5-15%", "15-30%", "30-40%", ">40%"],
            },
          ],
        },
        {
          title: "Other Incumbent Firm Advantages",
          weight: 100,
          questions: [
            {
              title: "Strength of incumbent brands",
              value: 0,
              weight: 100,
              options: ["Weak", "-", "Neutral", "-", "Strong"],
            },
            {
              title: "Customer switching costs",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Med", "-", "High"],
            },
            {
              title: "Network effect on buying decisions",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Med", "-", "High"],
            },
            {
              title: "Government regulation costs",
              value: 0,
              weight: 100,
              options: ["Low", "-", "Med", "-", "High"],
            },
          ],
        },
        {
          title: "Additional Considerations",
          weight: 100,
          questions: [
            {
              title: "Average incumbent profitability",
              value: 0,
              weight: 100,
              options: [">15%", "10-15%", "5-10%", "0-6%", "<0%"],
            },
            {
              title: "Industry growth rate",
              value: 0,
              weight: 100,
              options: [">5%", "3-5%", "1-3%", "0-1%", ">0%"],
            },
          ],
        },
      ],
    },
    {
      title: "Threat of Substitutes",
      weight: 100,
      sections: [
        {
          title: "Customer Awareness of Substitutes",
          weight: 100,
          questions: [
            {
              title: "Ability to recognize brands from substitute industry",
              value: 0,
              weight: 100,
              options: ["High", "-", "Med", "-", "Low"],
            },
          ],
        },
        {
          title: "Availability of Substitutes",
          weight: 100,
          questions: [
            {
              title: "Availability of substitutes",
              value: 0,
              weight: 100,
              options: ["Better", "-", "Same", "-", "Worse"],
            },
          ],
        },
        {
          title: "Price of Substitutes",
          weight: 100,
          questions: [
            {
              title: "Price of substitutes",
              value: 0,
              weight: 100,
              options: ["Lower", "-", "Same", "-", "Higher"],
            },
          ],
        },
        {
          title: "Performance of Substitutes",
          weight: 100,
          questions: [
            {
              title: "Performance of substitutes",
              value: 0,
              weight: 100,
              options: ["Better", "-", "Same", "-", "Worse"],
            },
          ],
        },
        {
          title: "Customer Switching Costs",
          weight: 100,
          questions: [
            {
              title: "Customer switching costs",
              value: 0,
              weight: 100,
              options: ["Lower", "-", "Med", "-", "High"],
            },
          ],
        },
      ],
    },
  ];

  const [userUpdateValues, setUserUpdateValues] = useState(
    userUpdateValuesDefaultLists,
  );
  const [presavedAnalytics, setPresavedAnalytics] = useState<TPresets[]>([]);

  const totalWeight = userUpdateValues.reduce(
    (acc, currentForce) => acc + currentForce.weight,
    0,
  );

  const calculatedValues = userUpdateValues.map((force) => {
    let forceWeightPerc = force.weight / totalWeight;

    let forceSectionWeightTotal = force.sections.reduce(
      (acc, section) => (acc += section.weight),
      0,
    );

    const forceScore = force.sections.reduce((acc, section) => {
      let sectionWeightPerc = section.weight / forceSectionWeightTotal;

      const sectionQuestionWeightTotal = section.questions.reduce(
        (acc, question) => (acc += question.weight),
        0,
      );

      const sectionScore = section.questions.reduce(
        (acc, question) =>
        (acc +=
          question.value * (question.weight / sectionQuestionWeightTotal)),
        0,
      );

      return (acc += sectionScore * sectionWeightPerc);
    }, 0);

    return { score: forceScore, weightPerc: forceWeightPerc * 100 };
  });

  async function GetSavedAnalytics() {
    if (!localStorage.getItem("token") || !localStorage.getItem("userId")) {
      return;
    }

    const data = await GetP5F();

    if (data.status === "success") {
      setPresavedAnalytics(
        data.result.map((force: TPortersFiveForcesDb) => ({
          ...force,
          forcesData: JSON.parse(force.forcesData),
        })),
      );
    } else {
      setPresavedAnalytics([]);
      console.error(data);
    }
  }

  useEffect(() => {
    GetSavedAnalytics();
  }, []);

  return (
    <P5fContext.Provider
      value={{
        GetSavedAnalytics,
        presavedAnalytics,
        userUpdateValues,
        setUserUpdateValues,
        calculatedValues,
      }}
    >
      <div className="d-flex">
        <div id="advanced-options">
          <div className="px-2 pt-3">
            <h3>Instructions</h3>
            <hr />
            <p>
              The Porter's Five Forces model weighs the relative influence of
              Rivalries, Buyers, Suppliers, New Entrants, and Substitutes. These
              forces are very broad and can be difficult to quantify. This
              advanced calculator breaks down each of those forces into simple
              subsections to help you better understand the forces at play in
              your industry. Set the values for each subsection and the
              calculator will calculate the overall industry attractiveness for
              you.
            </p>
            <p>
              You'll notice that this is a weighted calculator. This means that
              you can give more or less importance to each force depending on
              your industry. For example, in some industries, the threat of new
              entrants is a major concern, while in others, the power of buyers
              is the most important force. Use the sliders to adjust the weights
              of each section and force to better reflect your industry
              dynamics. Give more weight to the forces that are most important
              to your industry and give less to those that are less important.
            </p>
            <p className="fw-bold">
              After you complete your industry analysis, don't forget to save it
              so you can come back to it later. Saved analyses are available in
              the "Saved Analyses" section below.
            </p>
          </div>
          <hr />
          <PresavedList />

          <CalculatorInput />
          <Summary calculatedValues={calculatedValues} />
          <AnalyticsSaver />
        </div>
      </div>
    </P5fContext.Provider>
  );
}

function SimpleInput({
  force,
  setForce,
  totalMainWeight,
}: {
  force: {
    title: string;
    weight: number;
    value: number;
  };
  setForce: (values: { value: number; weight: number }) => void;
  totalMainWeight: number;
}) {
  const percent = (force.weight / totalMainWeight) * 100;

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between px-3 pt-2">
        <div className=" text-body">
          <h4>{force.title}</h4>
          <h6 className="mb-0">Score: {Math.round(force.value * 100) / 100}</h6>
        </div>
        <div
          className=" d-flex align-items-center justify-content-end"
          style={{ minWidth: 170 }}
        >
          <div className="me-2">Weight</div>
          <input
            type="number"
            step="any"
            pattern="\d*"
            defaultValue={force.weight}
            min={0}
            max={99}
            className="form-control mx-0"
            style={{ width: 60 }}
            onChange={(e: any) =>
              setForce({
                value: force.value,
                weight: parseFloat(e.target.value),
              })
            }
          />
          <div
            className=" d-flex align-items-center justify-content-center text-center"
            style={{ width: 50 }}
          >
            <small>{Math.round(percent * 100) / 100}%</small>
          </div>
        </div>
      </div>

      <div className="d-flex my-2">
        <div className="d-flex align-items-center w-100 fs-5">
          <div className="mx-2">1</div>
          <input
            type="range"
            value={force.value}
            min={1}
            max={5}
            className="form-range mx-0"
            onChange={(e: any) =>
              setForce({
                value: parseFloat(e.target.value),
                weight: force.weight,
              })
            }
            step="any"
          />
          <div className="mx-2">5</div>
        </div>
      </div>
    </div>
  );
}

function SimpleCalculator() {
  const defaultForces = [
    {
      title: "Rivalry",
      weight: 100,
      value: 3,
    },
    {
      title: "Power of Buyers",
      weight: 100,
      value: 3,
    },
    {
      title: "Power of Suppliers",
      weight: 100,
      value: 3,
    },
    {
      title: "Threat of New Entrants",
      weight: 100,
      value: 3,
    },
    {
      title: "Threat of Substitutes",
      weight: 100,
      value: 3,
    },
  ];

  const [forces, setForces] = useState(defaultForces);

  const totalWeight = forces.reduce((total, force) => {
    return total + force.weight;
  }, 0);

  const calculatedValues = forces.map((force) => {
    return {
      score: force.value,
      weightPerc: (force.weight / totalWeight) * 100,
    };
  });

  return (
    <div className="position-relative p-1  ">
      <div className="text-center mt-3 m-2 w-100">
        <b>Instructions:</b> Set the values for each force and the calculator
        will calculate the overall industry attractiveness. 1 is low and 5 is
        high. Set respective weights for each force if your industry values one
        force over another.
      </div>
      <div id="final-input">
        <table className="table table-striped">
          <tbody>
            {forces.map((force, forceIndex) => {
              return (
                <tr key={force.title}>
                  <td className="p-0">
                    <SimpleInput
                      key={forceIndex}
                      force={force}
                      setForce={(values) => {
                        let newForces = [...forces];
                        newForces[forceIndex].value = values.value;
                        newForces[forceIndex].weight = values.weight;
                        setForces(newForces);
                      }}
                      totalMainWeight={totalWeight}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Summary calculatedValues={calculatedValues} />
      </div>

      <button
        className="btn btn-primary "
        onClick={() => {
          setForces(defaultForces);
        }}
      >
        Reset All
      </button>
    </div>
  );
}

export default function PortersFiveForcesCalculator() {
  const [advancedCalculator, setAdvancedCalculator] = useState(true);

  return (
    <div className="position-relative w-100 ">
      <div className="text-center display-3 my-4">
        Porter's Five Forces Calculator
      </div>
      <p className="mx-2">
        Visit the{" "}
        <Link to={Concepts.PortersFiveForces.url}>Porter's Five Forces</Link>{" "}
        page for more details about the tool's definition, use, and history.
      </p>
      <div className="m-2">
        <button
          className="btn btn-primary w-100"
          onClick={() => {
            setAdvancedCalculator((advancedCalculator) => !advancedCalculator);
          }}
        >
          <h5 className="mb-0">
            {advancedCalculator
              ? "Use Simple Calculator"
              : "Use Advanced Calculator"}
          </h5>
        </button>
      </div>
      {advancedCalculator ? <AdvancedCalculator /> : <SimpleCalculator />}
    </div>
  );
}

import { Concepts } from "lib/data/Concepts";
import { Focuses } from "lib/data/Focuses";
import { Categories } from "lib/data/Categories";

export type TFocusKeys = keyof typeof Focuses;
export type TConceptsKeys = keyof typeof Concepts;
export type TCategoriesKeys = keyof typeof Categories;

export type TFocus = {
  id: string;
  name: string;
  question: string;
  color: string;
  url: string;
  num: number;
  summary: string;
  letter: string;
  imgSrc: string;
  filePath: string;
  type: "Focus";
};
export type TFocuses = {
  [key: string]: TFocus;
};
export const defaultFocus: TFocus = {
  filePath: "",
  id: "",
  name: "",
  question: "",
  color: "",
  url: "",
  num: 0,
  letter: "",
  summary: "",
  imgSrc: "",
  type: "Focus",
};

export type TPathway = {
  id: string;
  question: string;
  focus: TFocusKeys;
  name: string;
  imgSrc: string;
  url: string;
  concepts: TConceptsKeys[];
  summary: string;
  filePath: string;
  type: "Pathway";
};
export type TPathways = {
  [key: string]: TPathway;
};
export const defaultPathway: TPathway = {
  filePath: "",
  id: "",
  question: "",
  focus: "FoundationInPurpose",
  name: "",
  imgSrc: "",
  url: "",
  concepts: [],
  type: "Pathway",
  summary: "",
};

export type TConcept = {
  id: string;
  url: string;
  name: string;
  category: TCategoriesKeys;
  summary: string;
  essential: boolean;
  filePath: string;
  type: "Concept";
};
export type TConcepts = {
  [key: string]: TConcept;
};
export const defaultConcept: TConcept = {
  id: "",
  url: "",
  name: "",
  category: "Economics",
  essential: false,
  summary: "",
  filePath: "",
  type: "Concept",
};

export type TTool = {
  name: string;
  url: string;
  filePath: string;
  summary: string;
  imgSrc: string;
  type: "Tool";
};
export type TTools = {
  [key: string]: TTool;
};

export type TCategory = {
  name: string;
};
export type TCategories = {
  [key: string]: TCategory;
};

export type TStudent = {
  firstName: string;
  lastName: string;
  major: string;
  role: string;
  imgSrc: string;
  linkedin: string;
  started: Date;
  ended: string | null;
};

export type TVideo = {
  name: string;
  summary: string;
  url: string;
  thumbnail: string;
  type: "Video";
};

import { useElementPosition } from "components/tools/pages/PortersFiveForcesCalculator";
import { useEffect, useRef } from "react";

type TWedgeParams = {
  wedgeBottomAspectRatio: number;
  wedgeTopAspectRatio: number;
  WedgeImg: JSX.Element;
  TopBarImg: JSX.Element;
  BottomBarImg: JSX.Element;
};

export default function Wedge({
  wedgeTopAspectRatio,
  wedgeBottomAspectRatio,
  WedgeImg,
  TopBarImg,
  BottomBarImg,
}: TWedgeParams) {
  let [wedgeContainerRef, wedgeContainerPos] = useElementPosition();
  let [wedgeRef, wedgePos] = useElementPosition();
  let topBarRef = useRef<HTMLDivElement>(null);
  let bottomBarRef = useRef<HTMLDivElement>(null);
  let distBarToWallPx = 200;
  let containerWidth = 400;
  let containerHeight = 400;
  useEffect(() => {
    let wedgeElmnt = wedgeRef.current;
    if (wedgeElmnt) {
      let clientOffset = 0;
      let clientPosition = 0;
      let wedgeLeft = wedgePos.left;
      let wedgeRight = wedgePos.right;
      let wedgeContYCenter = wedgeContainerPos.height / 2;
      let moveable = true;

      wedgeElmnt.onmousedown = onMouseDown;

      function onMouseDown(e: any) {
        if (wedgeElmnt) {
          e.preventDefault();
          moveable = true;
          clientPosition = e.clientX;
          document.onmouseup = onMouseUp;
          document.onmousemove = onMouseMove;
        }
      }

      function onMouseUp() {
        moveable = false;
      }

      function onMouseMove(e: any) {
        e.preventDefault();

        let tempClientOffset = clientPosition - e.clientX;
        let tempWedgeLeft = wedgeLeft - tempClientOffset;
        let tempWedgeRight = wedgeRight - tempClientOffset;

        let isValidMove =
          moveable &&
          wedgeContainerPos.right >= tempWedgeRight &&
          wedgeContainerPos.left <= tempWedgeLeft;

        if (wedgeElmnt && isValidMove) {
          let topBar = topBarRef.current;
          let bottomBar = bottomBarRef.current;
          clientOffset = clientPosition - e.clientX;
          clientPosition = e.clientX;
          wedgeElmnt.style.left = wedgeElmnt.offsetLeft - clientOffset + "px";
          wedgeLeft -= clientOffset;
          wedgeRight -= clientOffset;
          if (topBar && bottomBar) {
            let halfBarWidth = topBar.offsetWidth / 2;
            if (wedgeRight - wedgeContainerPos.left - halfBarWidth > distBarToWallPx) {
              topBar.style.bottom =
                wedgeContYCenter +
                (wedgeRight - wedgeContainerPos.left - halfBarWidth - distBarToWallPx) *
                  wedgeTopAspectRatio +
                "px";
              bottomBar.style.top =
                wedgeContYCenter +
                (wedgeRight - wedgeContainerPos.left - halfBarWidth - distBarToWallPx) *
                  wedgeBottomAspectRatio +
                "px";
            } else {
              topBar.style.bottom = wedgeContYCenter + "px";
              bottomBar.style.top = wedgeContYCenter + "px";
            }
          }
        }
      }
    }
  }, [
    distBarToWallPx,
    wedgeBottomAspectRatio,
    wedgeContainerPos,
    wedgeContainerRef,
    wedgePos,
    wedgeRef,
    wedgeTopAspectRatio,
  ]);

  return (
    <div>
      <div
        id="wedge-container"
        className="wedge-bg position-relative d-flex align-items-center"
        ref={wedgeContainerRef}
        style={{ width: containerWidth, height: containerHeight }}
      >
        <div id="bar-container" className="position-absolute top-0 w-100 h-100">
          <div
            id="wedged-lower-bar"
            className=" position-absolute d-flex justify-content-end"
            ref={topBarRef}
            style={{ left: distBarToWallPx, height: "7.5em", bottom: "50%" }}
          >
            <div className="position-absolute mt-3">WTP</div>
            {TopBarImg}
          </div>
          <div></div>
          <div
            id="wedged-upper-bar"
            className=" position-absolute"
            ref={bottomBarRef}
            style={{ height: "7.5em", left: distBarToWallPx, top: "50%" }}
          >
            <div className="position-absolute mb-3 bottom-0 end-0">WTS</div>
            {BottomBarImg}
          </div>
        </div>
        <div id="wedge" className=" user-select-none position-absolute flex-end" ref={wedgeRef}>
          {WedgeImg}
        </div>
      </div>
    </div>
  );
}

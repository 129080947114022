import { TErrorRes } from "@sharedTypes/res.type";

function missingParametersError(param: string) {
  return {
    status: "failed",
    error: "argumentsMissing",
    log: "Parameters missing (client side)",
    message: "Please fill out all fields and try again.",
    content: param,
  } satisfies TErrorRes;
}

export default missingParametersError;

const BackToTopImg = require("lib/img/icons/collapse.png");

export default function BackToTop() {
  return (
    <div>
      <button
        className="btn btn-light p-0 rounded-circle shadow-dark"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        style={{ width: 40, height: 40 }}
      >
        <img
          src={BackToTopImg}
          alt=""
          className="w-100 h-100 p-2"
          style={{ transform: "rotate(90deg)" }}
        />
      </button>
    </div>
  );
}

import { SendOtp } from "api/email/SendOtp";
import { useState } from "react";
import { VerifyOtp } from "api/email/VerifyOtp";

type TVerifyOtpProps = {
  email?: string;
  setEmailVerified: (emailVerified: boolean) => void;
  showVerifier: boolean;
  setOtpToken?: (otpToken: string) => void;
  forgotPassword?: boolean;
};

export default function OtpVerifier({
  email,
  setEmailVerified,
  showVerifier,
  setOtpToken,
  forgotPassword = false,
}: TVerifyOtpProps) {
  const [emailSent, setEmailSent] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [userEmail, setEmail] = useState(email ?? "");

  async function SendEmailHandler(e: any) {
    e.preventDefault();
    const bodyEmail = e.target["email"]?.value ?? email ?? userEmail;
    const data = await SendOtp(bodyEmail, forgotPassword);
    if (data.status === "success") {
      setEmailSent(true);
      setEmail(bodyEmail);
      setOtpError("");
    } else {
      setOtpError(data.message);
      console.error(data);
    }
  }

  async function VerifyOtpHandler(e: any) {
    e.preventDefault();
    const otp = e.target["otp"].value;
    const data = await VerifyOtp(userEmail, otp);
    if (data.status === "success") {
      setEmailVerified(true);
      setOtpError("");
      if (setOtpToken) {
        setOtpToken(data.token);
      }
    } else {
      setOtpError(data.message);
      console.error(data);
    }
  }

  return (
    <div>
      <div className="position-relative">
        <div className="position-absolute" style={{ top: -100 }} id="otp-anchor" />
      </div>
      {showVerifier && (
        <div className="my-4 form-control alert alert-success position-relative">
          <h3>Verify Your Email</h3>
          {otpError && <div className="alert alert-danger">{otpError}</div>}
          {!emailSent ? (
            email ? (
              <div className="mt-3">
                <button className="btn btn-primary" onClick={SendEmailHandler}>
                  Send Verification Email to {email}
                </button>
              </div>
            ) : (
              <form onSubmit={SendEmailHandler}>
                <label htmlFor="email">Email: </label>
                <input
                  id="email"
                  name="email"
                  className="form-control"
                  type="text"
                  autoComplete="email"
                  required
                  autoFocus
                />
                <input type="submit" className=" btn btn-primary my-3" value={"Send Email"} />
              </form>
            )
          ) : (
            <>
              <form onSubmit={VerifyOtpHandler}>
                <label htmlFor="email">One-time Passcode</label>
                <input
                  id="otp"
                  name="otp"
                  className="form-control"
                  type="text"
                  maxLength={6}
                  autoComplete="one-time-code"
                  required
                  autoFocus
                />
                <input type="submit" className=" btn btn-primary my-3" value={"Submit"} />
              </form>
              <div className="mt-3">
                <div>
                  Didn't get an email? Make sure to check your spam folder and click below to get
                  another code.
                </div>
                <button className="btn btn-primary " onClick={SendEmailHandler}>
                  Resend Verification Email
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

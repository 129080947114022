import lottie from "lottie-web";
import { useEffect, useRef, useState } from "react";
import { animation as animationData } from "lib/data/AnimationData";

export default function AnimationTest() {
  const animationContainerType1 = useRef(null);
  // const animationContainerType2 = useRef(null);

  const [replay1, setReplay1] = useState(false);

  useEffect(() => {
    const container = animationContainerType1.current;

    let cleanup = () => {};
    if (container) {
      const anim = lottie.loadAnimation({
        container,
        animationData,
        renderer: "svg",
        loop: false,
      });
      cleanup = () => anim.destroy();
    }
    return () => cleanup();
  }, [replay1]);

  // const [replay2, setReplay2] = useState(false);

  // useEffect(() => {
  //   const container = animationContainerType2.current;

  //   let cleanup = () => {};
  //   if (container) {
  //     const anim = lottie.loadAnimation({
  //       container,
  //       animationData,
  //       renderer: "svg",
  //       loop: false,
  //     });
  //     cleanup = () => anim.destroy();
  //   }
  //   return () => cleanup();
  // }, [replay2]);

  return (
    <div className="d-flex align-items-center flex-column p-4">
      <div className="display-3">Animation Test</div>
      <button className="btn btn-primary" onClick={() => setReplay1((replay1) => !replay1)}>
        Replay Animation
      </button>
      <div
        ref={animationContainerType1}
        className="w-100 h-100 "
        style={{ maxWidth: window.innerWidth, maxHeight: window.innerHeight - 200 }}
      ></div>
      {/* <button className="btn btn-primary" onClick={() => setReplay2((replay2) => !replay2)}>
        Replay Animation
      </button>
      <div
        ref={animationContainerType2}
        className="w-100 h-100 "
        style={{ maxWidth: window.innerWidth, maxHeight: window.innerHeight - 200 }}
      ></div> */}
    </div>
  );
}

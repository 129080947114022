import { TConcepts } from "types/JsonType";

export const Concepts = {
  Differentiation: {
    url: "/user/concept/Growth/Differentiation",
    filePath: "components/concepts/pages/Differentiation",
    id: "Differentiation",
    name: "Differentiation",
    category: "Growth",
    summary: "Differentiation Summary",
    essential: true,
    type: "Concept",
  },
  AdjacentMarketExpansion: {
    url: "/user/concept/Growth/AdjacentMarketExpansion",
    filePath: "components/concepts/pages/AdjacentMarketExpansion",
    id: "AdjacentMarketExpansion",
    name: "Adjacent Market Expansion",
    category: "Growth",
    summary:
      "Adjacent Market Expansion is a business strategy that involves entering new markets related to a company's existing products or services, leveraging existing capabilities to drive growth and capitalize on market opportunities.",
    essential: true,
    type: "Concept",
  },
  BlueOcean: {
    url: "/user/concept/Innovation/BlueOcean",
    filePath: "components/concepts/pages/BlueOcean",
    id: "BlueOcean",
    name: "Blue Ocean Strategy",
    category: "Innovation",
    summary:
      "Blue Ocean Strategy is a business theory that focuses on creating uncontested market space by developing innovative products or services that appeal to new customers and avoid direct competition in existing markets.",
    essential: true,
    type: "Concept",
  },
  Cage: {
    url: "/user/concept/Growth/Cage",
    filePath: "components/concepts/pages/Cage",
    id: "Cage",
    name: "CAGE",
    category: "Growth",
    summary:
      "CAGE is a business strategy framework used to analyze the differences between countries, considering cultural, administrative, geographic, and economic factors, to identify opportunities and challenges in international expansion.",
    essential: false,
    type: "Concept",
  },
  ConsumptionChain: {
    url: "/user/concept/Innovation/ConsumptionChain",
    filePath: "components/concepts/pages/ConsumptionChain",
    id: "ConsumptionChain",
    name: "Consumption Chain",
    category: "Innovation",
    summary:
      "The consumption chain in business strategy refers to the series of steps a customer goes through from product awareness to purchase and use. Understanding this helps businesses plan their marketing and sales tactics effectively.",
    essential: true,
    type: "Concept",
  },
  EntryModes: {
    url: "/user/concept/Entrepreneurship/EntryModes",
    filePath: "components/concepts/pages/EntryModes",
    id: "EntryModes",
    name: "Entry Modes",
    category: "Entrepreneurship",
    summary:
      "Entry modes in business strategy refer to the ways a company enters a new market, such as exporting, licensing, franchising, joint ventures, or wholly-owned subsidiaries, each with its own advantages and risks.",
    essential: true,
    type: "Concept",
  },
  ExperienceCurve: {
    url: "/user/concept/Cost/ExperienceCurve",
    filePath: "components/concepts/pages/ExperienceCurve",
    id: "ExperienceCurve",
    name: "Experience Curve",
    category: "Cost",
    summary:
      "The Experience Curve is a business strategy concept that suggests costs decrease as a company gains experience in production, sales, and operations, leading to improved efficiency, profitability, and competitive advantage.",
    essential: false,
    type: "Concept",
  },
  IndirectAssault: {
    url: "/user/concept/Entrepreneurship/IndirectAssault",
    filePath: "components/concepts/pages/IndirectAssault",
    id: "IndirectAssault",
    name: "Indirect Assault",
    category: "Entrepreneurship",
    summary:
      "Indirect assault in business strategy refers to subtly undermining a competitor by targeting their weaknesses or market gaps, rather than directly challenging their strengths. It is typically done through innovation, differentiators, or niche marketing.",
    essential: true,
    type: "Concept",
  },
  InternationalStrategy: {
    url: "/user/concept/InternationalStrategy/InternationalStrategy",
    filePath: "components/concepts/pages/InternationalStrategy",
    id: "InternationalStrategy",
    name: "International Strategy",
    category: "InternationalStrategy",
    summary:
      "International Strategy is a business plan that guides a company's expansion into global markets, considering factors like market entry modes, cultural differences, regulatory environments, and competitive landscapes.",
    essential: true,
    type: "Concept",
  },
  JobsToBeDoneConcept: {
    url: "/user/concept/Innovation/JobsToBeDoneConcept",
    filePath: "components/concepts/pages/JobsToBeDoneConcept",
    id: "JobsToBeDoneConcept",
    name: "Jobs to be Done",
    category: "Innovation",
    summary:
      "The Jobs to be Done framework offers a lens through which businesses can understand the core needs or tasks that customers are trying to accomplish with their product or service, leading to customer-centric innovation and strategic decisions.",
    essential: true,
    type: "Concept",
  },
  PortersFiveForces: {
    url: "/user/concept/IndustryStructure/PortersFiveForces",
    filePath: "components/concepts/pages/PortersFiveForces",
    id: "PortersFiveForces",
    name: "Porter's Five Forces",
    category: "IndustryStructure",
    summary:
      '"Porter\'s Five Forces" is a strategic business tool that analyzes competitive environment by examining five forces: supplier power, buyer power, the threat of new entrants, substitute products, and intra-industry competition.',
    essential: true,
    type: "Concept",
  },
  ScaleCurve: {
    url: "/user/concept/Cost/ScaleCurve",
    filePath: "components/concepts/pages/ScaleCurve",
    id: "ScaleCurve",
    name: "Scale Curve",
    category: "Cost",
    summary:
      "The Scale Curve strategy in business pertains to the concept that with increased production scale and accumulated experience, costs per unit consistently decrease, thereby improving profitability and offering competitive advantage.",
    essential: false,
    type: "Concept",
  },
  StrategyCanvas: {
    url: "/user/concept/IndustryStructure/StrategyCanvas",
    filePath: "components/concepts/pages/StrategyCanvas",
    id: "StrategyCanvas",
    name: "Strategy Canvas",
    category: "IndustryStructure",
    summary:
      "The Strategy Canvas is a tool in strategic management & marketing, used to visualize a company's competitive position in relation to its rivals, highlighting factors that affect competition and potential areas for innovation.",
    essential: true,
    type: "Concept",
  },
  SunkCostFallacy: {
    url: "/user/concept/Investments/SunkCostFallacy",
    filePath: "components/concepts/pages/SunkCostFallacy",
    id: "SunkCostFallacy",
    name: "Sunk Cost Fallacy",
    category: "Investments",
    summary:
      "The Sunk Cost Fallacy in business strategy refers to the justification of increased investment in a project due to the considerable resources already spent, rather than considering its future viability and potential return on investment.",
    essential: false,
    type: "Concept",
  },
  ThreeHorizons: {
    url: "/user/concept/Growth/ThreeHorizons",
    filePath: "components/concepts/pages/ThreeHorizons",
    id: "ThreeHorizons",
    name: "Three Horizons Framework",
    category: "Growth",
    summary:
      "The Three Horizons Framework is a strategic model that helps businesses plan for future growth by visualizing their short-term, medium-term, and long-term goals across the three 'horizons' of growth, innovation and transformation.",
    essential: true,
    type: "Concept",
  },
  TripleA: {
    url: "/user/concept/InternationalStrategy/TripleA",
    filePath: "components/concepts/pages/TripleA",
    id: "TripleA",
    name: "The AAA Triangle",
    category: "InternationalStrategy",
    summary:
      "The AAA Triangle is a business strategy model that emphasizes three elements: agility, adaptation, and alignment. It guides firms to quickly respond to market changes, adapt to different environments, and align operations with strategy.",
    essential: false,
    type: "Concept",
  },
} as const satisfies TConcepts;

export default Concepts as TConcepts;

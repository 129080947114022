import Definition from "../templates/Definition";
import History from "../templates/History";

export default function IndirectAssault() {
  return (
    <div>
      <Definition>
        <p>
          How do companies enter markets where existing players possess key advantages in
          recognition, distribution and loyalty? Entrants can succeed using an indirect assault in
          which they do not confront incumbents head on. Instead they may perform one or a
          combination of the following actions to break into a market:
        </p>
        <ol>
          <li>Find Weaknesses</li>
          <li>Leverage Assets</li>
          <li>Build a Niche</li>
          <li>Expand Gradually</li>
        </ol>
        <p>
          Red Bull successfully entered the well-guarded soft drink industry by targeting a specific
          niche of energy beverages. It expanded it's offering by targeting 20 year olds in
          nightclubs and bars. Now RedBull is found in refrigerated cabinets next to industry icons
          like Coca-cola and Pepsi.
        </p>
      </Definition>
      <History>
        "Indirect Assault" despite not being a universally accepted theory, was coined in the
        Harvard Business Review Article "How to Crack Well-Guarded Markets" by Jeffrey Dyer and
        David Bryce in May 2007. Instead it's considered a specific strategic concept for target
        well-established markets. However, the idea of indirect assault builds upon various theories
        in strategic management such as the Market Penetration theory by Michael Porter wherein he
        postulating gaining market share by exploiting weaknesses and targeting niches. Another
        similar theory is the Blue Ocean Strategy by W. Chan Kim & Renee Mauborne focusing on
        creating uncontested space. Therefore the indirect assault draws upon many other theories to
        craft a compelling case for entering new markets.
      </History>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}

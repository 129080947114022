import constants from "Constants";
import UserContext from "context/UserContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SetDocumentTitle } from "utils/SetDocumentTitle";

export default function Account() {
  SetDocumentTitle("Account Settings");
  const { user } = useContext(UserContext);

  return (
    <div
      className="m-3 w-100 mx-auto"
      style={{ maxWidth: constants.contentWidth, minHeight: window.innerHeight - 100 }}
    >
      <div>
        <h1>Manage Account</h1>
        <p>Manage your account here.</p>

        <h2>Account Information</h2>
        <p>
          <b>First Name:</b> {user.firstName}
        </p>
        <p>
          <b>Last Name:</b> {user.lastName}
        </p>
        <p>
          <b>Email:</b> {user.email}
        </p>
        <Link className="btn btn-primary" to={"/user/account/editaccount"}>
          Edit Account
        </Link>
      </div>
    </div>
  );
}

import React from "react";
import constants from "Constants";

export default function Support() {
  return (
    <div
      className="d-flex justify-content-center w-100 align-items-center "
      style={{ minHeight: window.innerHeight - constants.navHeight }}
    >
      <div>email me at cgp27@byu.edu</div>
    </div>
  );
}

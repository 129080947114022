import Definition from "../templates/Definition";
import Example from "../templates/Example";
import ExampleContainer from "../templates/ExampleContainer";
import History from "../templates/History";
import Image from "../templates/Image";
import RelatedFrameworks from "../templates/RelatedFrameworks";
import Sources from "../templates/Sources";

// TODO: update
export default function JobsToBeDoneConcept() {
  return (
    <div>
      <Definition>
        <p>
          Jobs to be Done is a form of Differentiation Strategy through products. The term “jobs to
          be done” refers to the distinct ways a product can differentiate itself:
        </p>
        <ol>
          <li>Does a “better job” </li>
          <li>Does “more jobs” </li>
          <li>Does a “unique job” </li>
        </ol>
        <p>
          The purpose of these jobs to be done is to tailor your differentiation strategy to be more
          specific to the target audience. Jobs to be Done Strategy can be tailored based on the
          social, functional, or emotional aspects of a specific job that the product solves.
        </p>
        <p>
          Example- Scrub Daddy- Scrub Daddy sponges combined soft sponges and hard sponges for
          tougher greases and grimes into one sponge. Due to this product doing more jobs, this
          product took off and became the most successful company in Shark Tank history.
        </p>
      </Definition>
      <Image src={require("lib/img/concepts/Jobs_To_Be_Done_Types.png")} alt="jobs to be done" />
      <ExampleContainer>
        <Example header='Doing a "Better Job"'>
          <ul>
            <li>
              Ferrari- Ferrari is known for their sports cars because Ferrari creates cars that win
              competitive races. Therefore, they deliver a higher-quality car than other companies
              do.
            </li>
            <li>
              Lego- Lego is known for its products that are specific to franchises and create great
              experiences in comparison to other “building” children's products.
            </li>
            <li>
              Coca-Cola- Coca-Cola is known for its high-quality taste in comparison to other soda
              brands that cannot be matched.
            </li>
            <li>
              Razer- Razer is known for its high-quality products for gaming. From RGB keyboards,
              mouses with extra buttons, to premium headsets, Razer has much to offer in its product
              line.
            </li>
          </ul>
        </Example>
        <Example header='Does "more jobs"'>
          <ul>
            <li>
              Swiss Army Pocket Knife- Rather than just being able to do the job of one knife, the
              Swiss army pocketknife allows the user to have multiple knives in one.
            </li>
            <li>
              Kitchen Aid- through add-ons the Kitchen Aid product can perform many tasks to suit
              all baking and some cooking needs.
            </li>
            <li>
              Apple- when the iPhone was launched in 2007, it was innovative because it took the
              iPod technology with touchscreen capabilities and put it into a mobile phone. It could
              play music, make phone calls, text, and run applications. It was an all-in-one
              product.
            </li>
            <li>
              Google- Google functions not just as a search engine but also integrates AI
              capabilities, provides office software, and facilitates email communication via Gmail.
            </li>
          </ul>
        </Example>
        <Example header='Does "unique jobs"'>
          <ul>
            <li>
              Sabaton- this Swedish Metal band combines their unique playing style with lyrics about
              military history that cannot be found anywhere else.
            </li>
            <li>
              McDonalds- In 1948, the McDonalds brothers offered a unique service at their
              restaurant which was a quick way to receive food that no other restaurant offered at
              the time.
            </li>
            <li>
              Amazon- Amazon revolutionized the e-commerce industry through its quick and easy site
              navigation and quick shipping. It started out as an e-commerce site specializing in
              books, but quickly transformed into a super site with many different products from
              this unique job that they performed.
            </li>
          </ul>
        </Example>
      </ExampleContainer>
      <History>
        <p>
          Like unto Differentiation Strategy, the three Jobs to be Done have been present since the
          creation of the concept of business. However, it was not given its official name until the
          modern century. The first reference of Jobs to be done came in 2005 through a Harvard
          Business Review Article titled: “Marketing Malpractice: The Cause and the Cure”. In this
          article they built up and named the strategy of Jobs to be Done. Since then, many people
          have written and taught about the Jobs to be Done Strategy.
        </p>
      </History>
      <RelatedFrameworks unfinishedConcepts={["Differentiation Strategy"]} />
      <Sources>
        <ul>
          <li>
            Strategic Management: Concepts and Cases:
            <a href="https://www.wiley.com/en-us/Strategic+Management%3A+Concepts+and+Cases%2C+5th+Edition-p-9781394161881">
              https://www.wiley.com/en-us/Strategic+Management%3A+Concepts+and+Cases%2C+5th+Edition-p-9781394161881
            </a>
          </li>
          <li>
            <a href="https://fourweekmba.com/mcdonalds-speedee-system/">
              https://fourweekmba.com/mcdonalds-speedee-system/
            </a>
          </li>
          <li>
            <a href="https://www.seamgen.com/blog/iphone-history-original-iphone-to-current-iphone#:~:text=Apple's%20first%20iPhone%20was%20announced,it%20was%20a%20revolutionary%20device">
              https://www.seamgen.com/blog/iphone-history-original-iphone-to-current-iphone#:~:text=Apple's%20first%20iPhone%20was%20announced,it%20was%20a%20revolutionary%20device
            </a>
          </li>
          <li>
            <a href="https://hbr.org/2005/12/marketing-malpractice-the-cause-and-the-cure">
              https://hbr.org/2005/12/marketing-malpractice-the-cause-and-the-cure
            </a>
          </li>
        </ul>
      </Sources>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}

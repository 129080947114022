import { TResetPasswordReq } from "@sharedTypes/req.type";
import { TErrorRes, TResetPasswordRes } from "@sharedTypes/res.type";
import constants from "Constants";
import missingParametersError from "api/Error";
import axios from "axios";

export async function ResetPassword(email: string, password: string, token: string) {
  const apiUrl = constants.apiUrl + "/auth/resetpassword";

  const body: TResetPasswordReq = {
    email,
    password,
    token,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TResetPasswordRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}

import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PathwaysJson from "lib/data/Pathways";
import { TPathways, TConcepts } from "types/JsonType";
import ConceptsJson from "lib/data/Concepts";
import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import Focuses from "lib/data/Focuses";
import Tools from "lib/data/Tools";

const Pathways: TPathways = PathwaysJson;
const Concepts: TConcepts = ConceptsJson;

type TSearchValue = {
  name: string;
  type: string;
  url: string;
  parent?: string;
};

type TSearchbarProps = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
};

function CleanString(string: string): string {
  return string.replace(/[.,/#!$%^•&*;:{}=\-_`~()\s]/g, "").toLowerCase();
}

function GetSearchList() {
  const SearchFocus: TSearchValue[] = Object.values(Focuses).map((focus) => ({
    url: focus.url,
    type: "Focus",
    name: focus.letter + " - " + focus.name,
  }));

  const SearchPathways: TSearchValue[] = Object.values(Pathways).map((pathway) => ({
    url: pathway.url,
    type: "Pathway",
    name: pathway.name,
    parent: Focuses[pathway.focus].name,
  }));

  const SearchConcepts: TSearchValue[] = Object.values(Concepts).map((concept) => {
    const conceptPathway =
      Object.values(Pathways).find((pathway) => pathway.concepts.includes(concept.id as any))
        ?.name || "";
    return {
      url: `${concept.url}/${conceptPathway}`,
      type: "Framework",
      name: concept.name,
      parent: conceptPathway,
    };
  });

  const SearchTools: TSearchValue[] = Object.values(Tools).map((tool) => ({
    url: tool.url,
    type: "Tool",
    name: tool.name,
  }));

  const SearchList: TSearchValue[] = [
    ...SearchFocus,
    ...SearchPathways,
    ...SearchConcepts,
    ...SearchTools,
    { url: "/", type: "Main Page", name: "Home Page" },
    { url: "/codex", type: "Main Page", name: "The Codex" },
    { url: "/team", type: "Main Page", name: "The Team" },
    { url: "/videos", type: "Main Page", name: "Videos" },
    { url: "/FocusHome", type: "Main Page", name: "Focus Home" },
  ];

  return SearchList;
}

export default function Searchbar({ collapsed, setCollapsed }: TSearchbarProps) {
  const [searchInput, setSearchInput] = useState("");
  const [dropdownFilter, setDropdownFilter] = useState({
    name: "All",
    value: "All",
  });
  const navigate = useNavigate();

  const hasSearchInput = !!searchInput;

  useEffect(() => {
    if (!hasSearchInput) {
      setDropdownFilter({ name: "All", value: "All" });
    }
  }, [hasSearchInput]);

  const dropdownFilterOptions = [
    {
      name: "All",
      value: "All",
    },
    {
      name: "Pathways",
      value: "Pathway",
    },
    {
      name: "Frameworks",
      value: "Framework",
    },
    {
      name: "Focuses",
      value: "Focus",
    },
    {
      name: "Tools",
      value: "Tool",
    },
    {
      name: "Main Pages",
      value: "Main Page",
    },
  ];

  const SearchList = useMemo(() => GetSearchList(), []);

  let searchResults = SearchList.slice();

  let input = CleanString(searchInput);

  searchResults = searchResults.filter((searchValue) => {
    const matchesSearch = !!CleanString(searchValue.name).match(input);
    const matchesType = dropdownFilter.value === "All" || searchValue.type === dropdownFilter.value;
    return matchesSearch && matchesType;
  });

  searchResults.sort((a, b) => {
    const aName = CleanString(a.name);
    const bName = CleanString(b.name);

    if (aName.startsWith(input) && !bName.startsWith(input)) {
      return -1;
    } else {
      return 0;
    }
  });

  searchResults = searchResults.slice(0, 15);

  const submitHandler = (e: any) => {
    if (e.keyCode === 13) {
      if (searchResults.length === 0) {
        navigate(`/user/search?search=${searchInput}`);
      } else if (searchResults.length !== SearchList.length) {
        const listItem = searchResults[0];
        navigate(listItem.url);
      }
    }
  };

  window.addEventListener("click", (e: any) => {
    const ids = ["input-group-dropdown-1", "search-link", "search-input", "dropdown-link"];

    if (!ids.includes(e.target.id)) {
      setSearchInput("");
    }
  });

  return (
    <div className="h-100" id="searchbar">
      <div className="position-relative">
        <InputGroup>
          <DropdownButton
            variant={`primary  text-decoration-none ${
              (searchInput || dropdownFilter.value !== "All") && "rounded-bottom-0"
            } `}
            title={dropdownFilter.name}
            id="input-group-dropdown-1"
            style={{ textDecorationColor: "rgb(254,254,254)" }}
          >
            <Dropdown.Item
              onClick={() =>
                setDropdownFilter({
                  name: "All",
                  value: "All",
                })
              }
              id="dropdown-link"
            >
              All
            </Dropdown.Item>
            <Dropdown.Divider />
            {dropdownFilterOptions.map((option, i) => {
              if (i === 0) {
                return null;
              }
              return (
                <Dropdown.Item
                  key={option.value}
                  onClick={() => setDropdownFilter(option)}
                  id="dropdown-link"
                >
                  {option.name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>

          <input
            autoComplete="off"
            type="text"
            id="search-input"
            className={`form-control ${
              (searchInput || dropdownFilter.value !== "All") && "rounded-bottom-0"
            } `}
            placeholder="Search"
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            value={searchInput}
            style={{ minWidth: 300, height: 40 }}
            onKeyDown={submitHandler}
          />
        </InputGroup>

        {(searchInput || dropdownFilter.name !== "All") && (
          <div className="position-absolute w-100 rounded-bottom-3 border border-secondary border-top-0 overflow-hidden bg-tan-2 z-3">
            {searchResults.map((searchResult, i) => (
              <Link to={searchResult.url} className="no-underline" key={i}>
                <div
                  className="text-body px-2 py-1 border-top border-secondary d-flex justify-content-between align-items-center bg-tan-3-hover"
                  id="search-link"
                  onClick={() => setCollapsed(true)}
                >
                  <div id="search-link">
                    {searchResult.name}
                    {searchResult.parent && <span id="search-link"> - {searchResult.parent}</span>}
                  </div>
                  <div className="text-secondary fs-8" id="search-link">
                    {searchResult.type}
                  </div>
                </div>
              </Link>
            ))}
            <Link
              to={`/user/search?search=${searchInput}`}
              className="d-block text-body text-decoration-none px-2 py-1 border-top border-secondary bg-light-grey-blue"
              id="search-link"
              onClick={() => {
                setCollapsed(true);
              }}
            >
              Go To Advanced Search: {searchInput}
              {/* <div
                className="cursor-pointer fw-bold fs-6 noto-serif  text-wrap"
                id="search-link"
              ></div> */}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

import CapabilitiesMap from "../maps/CapabilitiesMap";

export default function Capabilities() {
  return (
    <div>
      <p>
        The structure of your business model determines how operational processes and activities
        support your overarching strategy and lead to a competitive advantage. It proposes how to
        deliver value to your customers. By examining these components, you can ensure alignment
        between your strategic objectives and your day-to-day operations.
      </p>

      <CapabilitiesMap />
    </div>
  );
}

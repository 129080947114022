import React from "react";

export default function Purpose() {
  return (
    <div>
      <p>
        In the story, Alice in Wonderland, Alice asks the Cheshire Cat "which road do I take?" The
        cat responds, "Where do you want to go?" Alice replies, "I don't know," and the cat says,
        "Then it doesn't matter which way you go."
      </p>
      <p>
        The word strategy is defined as "a plan of action or policy designed to achieve a major or
        overall purpose or intention; a desired outcome." (Oxford English Dictionary). Without
        knowing what the desired outcome is there can be no plan of action to achieve it. Good
        examples of purpose are Apple, Nike, and Patagonia. They are clear in what they will do and
        not do.
      </p>
      <p>
        This section is to give direction and guidance to make a company mission, vision, and values
        that will define what a company will pursue and what it will not pursue, financially and
        ethically. Unfortunately, its not as cut and dry as filling out the boxes of mission,
        vision, and values, and its done.
      </p>
    </div>
  );
}

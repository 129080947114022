import Definition from "components/concepts/templates/Definition";
import ExampleContainer from "../templates/ExampleContainer";
import Example from "../templates/Example";

export default function StrategyCanvas() {
  return (
    <div>
      <Definition>
        <p>
          The strategy canvas serves as a comprehensive framework for analyzing and comparing
          competitive factors within an industry. It answers the question of “Where is our product
          winning and where is it losing?” The strategy canvas emphasizes strategic positioning and
          differentiation.
        </p>
        <p>
          To construct a strategy canvas, a firm must consider crucial industry factors that
          influence competition. These could include product features, service levels, pricing, and
          other key dimensions. Consider identifying these dimensions through the use of the “Jobs
          to be done” framework.
        </p>
        <p>
          The canvas is then created by plotting the company's performance on these factors,
          providing a visual representation of its strategic profile. When plotting these factors,
          your x-axis will be each factor, or job done by your product. Then, your y-axis will be
          defined by the level of performance your product has for each individual factor. This is
          often done on a 1-10 scale and is best derived from survey data in order to determine
          customer perception of your product. However firms can also rate themselves, this should
          only be done as a “quick and dirty test” to consider where your firm is differentiated
          from competitors, not as a measure of customer perceptions.
        </p>
        <p>
          The canvas allows businesses to map out their current competitive landscape and identify
          areas where they can stand out from the competition. By visually comparing different
          strategic profiles, companies can spot opportunities to innovate, create unique value
          propositions, and potentially redefine industry boundaries. By regularly revisiting and
          updating the canvas, companies can stay ahead of industry shifts, respond to changing
          customer preferences, and continuously refine their value propositions. The strategy
          canvas is an invaluable resource for businesses looking to understand, visualize, and
          strategically position themselves in the competitive landscape.
        </p>
      </Definition>
      <ExampleContainer>
        <Example header="Smart Phones">
          Consider the smartphone industry, where companies compete on factors like design,
          features, ecosystem integration, and pricing. Apple, with its iPhone, strategically
          positioned itself with a focus on sleek design, user-friendly interfaces, and a tightly
          integrated ecosystem. On the strategy canvas, Apple's profile would emphasize these
          factors, setting it apart from competitors who might prioritize different dimensions.
        </Example>
        <Example header="Auto Market">
          A strategy canvas can also be used to identify a product's most direct competition within
          a market. This is demonstrated within the automobile market. Comparing vehicles such as a
          GMC Sierra, a large, heavy-duty truck, and a Kia Rio, a sub-compact sedan, would produce a
          strategy canvas with massive disparities on many factors. The jobs to be done by each of
          these vehicles are vastly different, and as such, their performance in many factors will
          be dramatically different. Now consider a strategy canvas that consists of a Honda Accord,
          Nissan Altima, and Toyota Camry. Each of these vehicles are full-size sedans of similarly
          positioned brands, therefore, a strategy canvas is going to show similar performance on
          many of the features that customers value in these vehicles. There are likely to be only a
          few minor disparities, effectively identifying where these sedans, targeting the same
          customers, are differentiating or failing to do so.
        </Example>
      </ExampleContainer>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}

import constants from "Constants";
import Error from "components/templates/Error";
import Tools from "lib/data/Tools";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TTool } from "types/JsonType";

function Tool({ tool }: { tool: TTool }) {
  const [ImportedComponent, setImportedComponent] = useState(null) as any;

  useEffect(() => {
    async function ImportComponents(filePath: string) {
      setImportedComponent(null);
      await import(`${filePath}`).then((module) => {
        const AnotherComponent = module.default;
        setImportedComponent(<AnotherComponent />);
      });
    }
    ImportComponents(tool.filePath);
  }, [tool]);

  return (
    <div
      className="mx-auto w-100"
      style={{ maxWidth: constants.maxPageWidth, minHeight: window.innerHeight - 100 }}
    >
      {ImportedComponent}
    </div>
  );
}

export default function ToolsRoot() {
  const { toolId } = useParams();
  if (!toolId) return <Error message={"404. Page not found"} />;
  const tool = Tools[toolId];

  if (!tool) return <Error message={"404. Page not found"} />;
  return <Tool tool={tool} />;
}

import React from "react";
import Definition from "../templates/Definition";
import ExampleContainer from "../templates/ExampleContainer";
import Example from "../templates/Example";
import RelatedFrameworks from "../templates/RelatedFrameworks";
import { Concepts } from "lib/data/Concepts";
import History from "../templates/History";

export default function BlueOcean() {
  return (
    <div>
      <Definition>
        <p>
          One of the main difficulties in business is trying to compete with so many alternatives.
          Blue ocean strategy was developed to create and capture uncontested market space. By
          pursuing both differentiation and low cost, business leaders can reimagine market
          boundaries and industry structure to innovate their way to success.
        </p>
        <p>
          Example - <b>Dollar Shave Club</b>: The traditional razor market was dominated by
          expensive, multi-blade razors with frequent cartridge replacements (Gilette). The Dollar
          Shave Club created a <b>blue ocean</b> by offering a <b>subscription model</b> of{" "}
          <b>high-quality</b>, <b>single-blade razors</b> at a <b>significantly lower price</b>.
          They targeted a segment of consumers who found traditional razors overpriced and
          unnecessary, providing a <b>simple and affordable alternative</b>.
        </p>
        <div>
          Creating blue ocean strategies can be diluted down to five points all directed questioning
          industry assumptions.
        </div>
        <ol>
          <li>
            Make the competition irrelevant by offering leaps in value through innovation. This can
            include producing new products that engender new markets.
          </li>
          <li>
            Shape industry structure to your advantage by your strategy. Instead of vertical
            competition, look for horizontal expansion.
          </li>
          <li>
            Consider not just existing customers, but also non-customers as prime targets for your
            products. These may contain the greatest insights for revolutionizing your industry.
          </li>
          <li>
            Review modes of industry improvement by speaking to past customers, non-customers, and
            new customers. This will help you tailor new products and strategies towards expansion
            of the industry.
          </li>
          <li>
            Balance your strategy by maintaining focus on low cost and differentiation. This will be
            the ultimate key to creating a winning strategy.
          </li>
        </ol>
      </Definition>
      <ExampleContainer>
        <Example header="AirBnB">
          The hospitality industry was dominated by traditional hotels and motels. Airbnb created a
          blue ocean by offering a peer-to-peer platform allowing individuals to rent out their own
          homes or spaces to travelers. This catered to a growing demand for unique and authentic
          experiences at competitive prices. Additionally, it offered homeowners the opportunity to
          generate income by utilizing their unused space.
        </Example>
        <Example header="Cirque du Soleil">
          Traditional circuses were often seen as catering to children and families. Cirque du
          Soleil, however, created a blue ocean by offering a high-end, theatrical circus experience
          for adults. They combined artistic elements with traditional circus acts, appealing to a
          broader audience and offering a unique value proposition.
        </Example>
        <Example header="Apple iPod">
          While portable MP3 players existed before the iPod, they were often bulky and
          inconvenient. Apple created a blue ocean with the iPod by offering a sleek, user-friendly
          design with a large music library through iTunes. This combination of design,
          functionality, and content created a new market for stylish and easy-to-use music players.
        </Example>
      </ExampleContainer>
      <RelatedFrameworks concepts={[Concepts.ThreeHorizons, Concepts.AdjacentMarketExpansion]} />
      <History>
        In 2005, the book “Blue Ocean Strategy, How to Create Uncontested Market Space” was written
        by Renee Mauborgne and Chan Kim. This book authored the beginning of blue ocean strategy by
        pursuing both low cost and differentiation. The systematic approach detailed by the book led
        to wide adoption across various sectors. Following the worldwide success of “Blue Ocean
        Strategy” the later named #1 Management thinkers in the world wrote “Blue Ocean Shift” to
        describe how to move beyond industry competition to inspire employee confidence and seize
        new growth opportunities.
      </History>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}

import constants from "Constants";
import React from "react";

type TPageTitleBannerProps = {
  id: string;
  title: string;
  img: string;
  imgPosition: string;
  description?: string;
  link?: string;
  linkText?: string;
  children?: React.ReactNode;
};

export default function PageTitleBanner({
  id,
  title,
  img,
  imgPosition,
  description,
  link,
  linkText,
  children,
}: TPageTitleBannerProps) {
  return (
    <div id={id} className="d-flex justify-content-center align-items-center position-relative">
      <div
        id={id + "-anchor"}
        className="position-absolute"
        style={{ top: -constants.navHeight }}
      />
      <img
        className="position-absolute object-fit-cover h-100 w-100"
        src={img}
        alt="strategy unlocked"
        style={{ opacity: "0.5", objectPosition: imgPosition }}
      />
      <div className="bg-blur w-100 my-5">
        <div className="container">
          <div className="row h-100 rounded py-3 text-dark" style={{ minHeight: 200 }}>
            <div
              className={
                "d-flex justify-content-center align-items-center " +
                (description ? "col-sm-6" : "col-12")
              }
            >
              <div className="text-center display-1 m-0 p-0 d-flex align-items-center">
                {title}
                {link && (
                  <a href={link} className="btn btn-primary">
                    To {linkText}
                  </a>
                )}
              </div>
            </div>
            {description && (
              <div className="col-sm-6 d-flex justify-content-center align-items-center">
                <div className="d-block text-center">
                  <p className="">{description}</p>
                  <div className=""></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}

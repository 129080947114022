import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import UserContext from "context/UserContext";
import Nav from "components/navigation/Nav";
import Footer from "components/Footer";
import useUser from "hooks/useUser";
import { ReportWebUsage } from "api/webUsage/ReportWebUsage";
import LoginSoon from "components/LoginSoon";
import ChatWindow from "components/chat_bot/ChatWindow";

function HighlightResult(sourceStr: string, searchInput: string) {
  const regex = new RegExp(searchInput, "gi");
  const matches = Array.from(new Set(sourceStr.match(regex) || []));

  matches.forEach((match) => {
    sourceStr = sourceStr.replaceAll(match, `<span class="highlight">${match}</span>`);
  });

  sourceStr = sourceStr.replaceAll(
    `<span class="highlight"><span class="highlight">`,
    `<span class="highlight">`
  );

  return sourceStr;
}

export default function App() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchResultParam = searchParams.get("searchResult");
    if (searchResultParam) {
      // Hacky Solution: Wait for the Dom to fully load
      setTimeout(() => {
        const matchingElements = [
          ...document.querySelectorAll(":not(script):not(style):not(meta):not(title):not(link)"),
        ].filter((element) => {
          console.log(element.NOTATION_NODE);
          return element.childNodes?.[0]?.nodeValue
            ?.toLowerCase()
            .includes(searchResultParam.toLowerCase());
        });

        if (matchingElements.length !== 0) {
          matchingElements.forEach((element) => {
            element.innerHTML = HighlightResult(element.innerHTML, searchResultParam);
          });

          matchingElements[0].scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
          });
        }
      }, 300);
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }

    let mounted = true;

    setTimeout(() => {
      if (mounted) ReportWebUsage(pathname);
    }, 1000 * 7); // 7 seconds

    return () => {
      mounted = false;
    };
  }, [pathname, searchParams]);

  document.documentElement.setAttribute("data-bs-theme", "light");

  const { loginStatus, sessionExpiration, user, setUser } = useUser();

  return (
    <UserContext.Provider value={{ loginStatus, user, setUser }}>
      <div className="position-relative">
        <LoginSoon loginStatus={loginStatus} sessionExpiration={sessionExpiration} />
        <Nav />
        <div className="d-flex position-relative">
          <div className="w-100">
            <div className="d-flex w-100" style={{ minHeight: window.innerHeight - 100 }}>
              <Outlet />
            </div>
            <Footer />
          </div>
          <ChatWindow />
        </div>
      </div>
    </UserContext.Provider>
  );
}

import React from "react";

export default function Moat() {
  return (
    <div>
      <p>
        Safeguards are to protect the business after it is setup and running. A good CEO knows that
        nothing in business is status quo, and that what works today may not (or will not) work
        tomorrow. They must (1) understand what their current competitive advantage is, (2) ensure
        that the company can protect or maintain that advantage, and (3) what will the company do if
        that competitive advantage dissapears.
      </p>
    </div>
  );
}

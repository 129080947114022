import React, { useEffect, useState } from "react";
type TLoginSoonProps = {
  loginStatus: string;
  sessionExpiration: number;
};
export default function LoginSoon({ loginStatus, sessionExpiration }: TLoginSoonProps) {
  // every 10 seconds, check if the user's session is about to expire
  // if there are less than 5 minutes left, show a warning
  // if their token has expired, try to authenticate them.
  const [loginSoon, setLoginSoon] = useState(false);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (loginStatus === "logged in") {
        if (sessionExpiration < Date.now()) {
          window.location.reload();
        } else if (sessionExpiration < Date.now() + 1000 * 60 * 5) {
          setLoginSoon(true);
        }
      }
    }, 1000 * 10); // 10 seconds

    return () => {
      clearInterval(timeout);
    };
  }, [loginStatus, sessionExpiration]);

  return (
    <div>
      {loginSoon && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 text-light d-flex justify-content-center align-items-center z-1"
          style={{ backgroundColor: "#000b" }}
          onClick={() => {
            setLoginSoon(false);
            window.location.reload();
          }}
        >
          <div className="text-center bg-dark rounded p-4">
            <h1 className="">Your session is about to expire.</h1>
            <h2 className="">Click anywhere to continue.</h2>
          </div>
        </div>
      )}
    </div>
  );
}

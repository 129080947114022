import React from "react";
import Definition from "../templates/Definition";
import Image from "../templates/Image";
import ExampleContainer from "../templates/ExampleContainer";
import Example from "../templates/Example";
import RelatedFrameworks from "../templates/RelatedFrameworks";
import { Concepts } from "lib/data/Concepts";
import History from "../templates/History";

export default function InternationalStrategy() {
  return (
    <div>
      <Definition>
        Selecting the appropriate international strategy can be challenging. It must align with your
        company objectives and unique industry pressures. By comparing forces of globalization and
        forces of localization, three main international strategies have emerged, suitable for any
        expansionist company.
        <ol>
          <li>
            Multidomestic Strategy - A strategy whereby global firms respond to differentiated needs
            and preferences in each local market where they operate.
          </li>

          <li>
            Meganational Strategy - A strategy wherein global firms focus on increasing profitable
            growth by reaping cost reductions from economies of scale and other advantages of global
            integration.
          </li>

          <li>
            Transnational Strategy - A strategy where by global firms take a hybrid approach
            combining multidomestic and meganational approaches by simultaneously offering benefits
            of global and local advantages.
          </li>
        </ol>
        <p>
          By learning about each strategy, business owners can identify which strategy can be the
          best suited to their business.
        </p>
      </Definition>
      <Image
        src={require("lib/img/concepts/International_Strategy.png")}
        alt="infographic describing the three international strategies"
      />
      <ExampleContainer>
        <Example header=" Multidomestic - Nestle">
          When Nestle expanding to China, it discovered that its globally branded Kitkat bar wasn't
          quite making the impact that it had on other countries. As a result, the Chinese nestle
          team designed produced and marketed a new type of chocolate wafer bar at a fraction of the
          cost of Kitkat. Today, it is the best selling candy bar in China.
        </Example>
        <Example header="Meganational - ECCO">
          The Danish shoe company sells the same shoes all over the world. It applies a similar
          market strategy and has focused manufacturing in a few key countries with competitive
          advantages in shoe production.
        </Example>
        <Example header="Transnational - GE">
          GE is known for having globally standardized procedures but have begun to encourage
          foreign subsidiaries to develop and share innovative new ideas for the rest of the global
          organization. This has enabled multiple products to be developed for local markets such as
          compact and inexpensive heart monitoring devices.
        </Example>
      </ExampleContainer>
      <RelatedFrameworks
        concepts={[Concepts.Cage, Concepts.BlueOcean, Concepts.EntryModes]}
        unfinishedConcepts={["PEST Framework"]}
      />
      <History>
        Two strategy professors at Brigham Young University, Shad Morris and Jim Oldroyd sought to
        standardize the modes of international strategy. In their published book on International
        business, they outlined the above model which differentiated the various expansion modes.
        This model has helped illustrate and grant understanding to the field of international
        strategy.
      </History>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}

import { Concepts } from "lib/data/Concepts";
import Definition from "../templates/Definition";
import Example from "../templates/Example";
import ExampleContainer from "../templates/ExampleContainer";
import History from "../templates/History";
import RelatedFrameworks from "../templates/RelatedFrameworks";
import Sources from "../templates/Sources";
import Wedge from "../templates/Wedge";

const HalfWedgeSrc = require("lib/img/differentiation_wedges/Half_Wedge.png");
const FullWedgeSrc = require("lib/img/differentiation_wedges/Full_Wedge.png");
const TopLineSrc = require("lib/img/differentiation_wedges/Top_Line.png");
const BottomLineSrc = require("lib/img/differentiation_wedges/Bottom_Line.png");

export default function Differentiation() {
  const TopBarImg = (
    <img width={100} src={TopLineSrc} alt="placeholder" className="object-position-bottom" />
  );
  const BottomBarImg = <img width={100} src={BottomLineSrc} alt="placeholder" />;
  const TopHalfWedgeImg = (
    <div className="position-relative">
      <div className="position-absolute top-0 start-0 ms-4" style={{ marginTop: "4rem" }}>
        Differentiation
      </div>
      <img width={200} height={200} src={HalfWedgeSrc} alt="placeholder" />
    </div>
  );
  const BottomHalfWedgeImg = (
    <div>
      <div className="position-absolute bottom-0 start-0 ms-4" style={{ marginBottom: "4rem" }}>
        Cost Advantage
      </div>
      <img
        style={{ transform: "scale(1, -1)" }}
        width={200}
        height={200}
        src={HalfWedgeSrc}
        alt="placeholder"
      />
    </div>
  );
  const FullWedgeImg = (
    <div>
      <div className="position-absolute d-flex ms-4 align-items-center  h-100">
        <div>Differentiation</div>
      </div>
      <img width={200} height={200} src={FullWedgeSrc} alt="placeholder" />
    </div>
  );

  return (
    <div>
      Differentiation | Features | Convenience | Brand | Quality | Product Quality | Customer
      Service | Jobs to be Done | Consumption Chain | Strategy Canvas | Buyer Utility Map
      <Definition>
        Differentiation is the way a company separates itself from the rest of the competition. It
        is one of the fundamental and foundational building blocks in strategy to build brand
        recognition, increase market share, and to grow as a company. It does this by raising a
        customer’s willingness to pay through comparative improvements in (i) the product and (ii)
        the customer interaction. See the graphic below for examples of improvements to each
        differentiation subcategory:
      </Definition>
      <p></p>
      <p>*Insert Differentiation Venn Diagram*</p>
      <p>
        The product sub-category focuses on differentiating through certain aspects of a product
        while the customer sub-category focuses on differentiating through certain aspects that
        interact with a customer.
      </p>
      <p>*Insert Jeep Video Graphic right here*</p>
      <ExampleContainer>
        <Example header="Apple">
          Apple is known for its innovation and high-quality products. It differentiates itself from
          its competitors in many aspects; its marketing, branding, and products.: all evoke high
          quality perceptions. It pays attention to details that other firms ignore such as
          packaging. High quality packaging leads to a unique “unboxing” experience. All of this
          leads to product differentiation.
        </Example>
        <Example header="Fender">
          Fender- Fender Stratocaster guitars are known for their three-coil pickup system for
          diverse tone possibility from clean to heavy sounds. Additionally, Stratocasters are
          heavily customizable for whichever sound quality someone may want. On the other hand,
          other guitar companies such as Gibson primarily utilize humbucker pickups that capture
          deep and heavy tones and are not so easily customized. Fender’s niche strategy for
          customizable tones is focused differentiation.
        </Example>

        <Example header="Chick-Fil-A">
          One of the many ways Chick-Fil-A differentiates itself is through superior customer
          service. They always seek to delight the customers by politely serving them. It is no
          surprise that they are consistently ranked high in the American Customer Satisfaction
          Index; in 2024 they were ranked 1st in the fast-food industry. Due to their quality
          customer service, they have loyal customers and been seeing consistent growth.
        </Example>
      </ExampleContainer>
      <h1>How it fits into Generic Strategies</h1>
      <p>*Insert Confusion Matrix Right Here*</p>
      <p>
        As shown in the Generic Strategies framework *Hyperlink to codex article*, leaders choosing
        to leverage differentiation must choose whether to target the industry broadly or to target
        a narrower portion of the industry. Broad differentiation is to differentiate for many
        segments in industry while Focused Differentiation is provides differentiation within a
        specific segment of the market. For example, while American Airlines offers standard flights
        to virtually between any two locations in the United States at any time and focuses on
        multiple segments like family travel, business travel and everything in between. On the
        other hand, Southwest Airlines offers a focused travel experience that works to get people
        out of their car and onto a flight. In this case, Southwest operates in a more “niche”
        market than American Airlines in both the locations served and the people flown. This is
        manifest in the types of flights flown. For example, Southwest does not currently offer
        overnight “red-eye” flights. Over time, companies like Southwest may seek to shift between
        Focused Differentiation and Differentiation to capture greater overall market share.
      </p>
      <p>*Insert Video Here*</p>
      <h1>Application</h1>
      <p>
        Many frameworks have been created in order to assist Strategists in assessing how they are
        currently differentiating and how they can differentiate more. Some examples include the
        Strategy Canvas and the Buyer Utility Map (see Frameworks below for a more comprehensive
        list).
      </p>
      <RelatedFrameworks
        concepts={[
          Concepts.JobsToBeDoneConcept,
          Concepts.ConsumptionChain,
          Concepts.StrategyCanvas,
        ]}
        unfinishedConcepts={["Buyer Utility Map"]}
      />
      <History>
        Although not given its official name until the modern era, Differentiation Strategy has been
        around since the creation of business and competition. Throughout history, businesses have
        tried to differentiate themselves from the competition by either having the best product
        quality or best customer interaction. One of the earliest records of a customer evaluating
        these factors of differentiation was filed to EA Nasir around 1750 B.C. In this cuneiform
        tablet, the customer complained about his rudeness during the sale of substandard copper.
        This goes to show that even in ancient times, customers looked for the best quality product
        or the best customer interaction and differentiation matters.
      </History>
      <Sources>
        <div>
          <p>
            MacMillan, I., & McGrath, R. (1997, July). “Discovering the New Points of
            Differentiation”. Harvard Business Review.
            https://hbr.org/1997/07/discovering-new-points-of-differentiation
          </p>
          <p>
            Dyer, J., Godfrey, P., Jensen, R., Bryce, D. (2024, February). “Strategic Management:
            Concepts and Cases, 5th Edition”, Chapter 5, pp 81-97. Wiley.
            https://www.wiley.com/en-us/Strategic+Management%3A+Concepts+and+Cases%2C+5th+Edition-p-9781394161881
          </p>
          <p>
            Institute for Manufacturing. (2016). “Porter's Generic Competitive Strategies (ways of
            competing)”. University of Cambridge.
            https://www.ifm.eng.cam.ac.uk/research/dstools/porters-generic-competitive-strategies/
          </p>
          <p>Porter, M. (1985). "Competitive Advantage", Ch. 1, pp 11-15. The Free Press.</p>
          <p>
            ACSI Staff. (2024, March). “Fast Food Restaurants”. American Customer Satisfaction
            Index. https://theacsi.org/industries/restaurant/fast-food-restaurants/
          </p>
          <p>Chick-Fil-A financial statements: Chick-Fil-A-2022.pdf</p>
          <p>
            Chick-fil-A, Inc. (2023, March). “Chick-fil-A 2022 Franchise Disclosure Document”.
            Franchise Times. https://www.franchisetimes.com/app/Chick-Fil-A-2022.pdf
          </p>
          <p>
            Southwest:
            https://www.investopedia.com/articles/investing/061015/how-southwest-different-other-airlines.asp
          </p>
        </div>
      </Sources>
      <Wedge
        WedgeImg={TopHalfWedgeImg}
        wedgeTopAspectRatio={0.55}
        wedgeBottomAspectRatio={0}
        TopBarImg={TopBarImg}
        BottomBarImg={BottomBarImg}
      />
      <Wedge
        WedgeImg={FullWedgeImg}
        wedgeTopAspectRatio={0.5}
        wedgeBottomAspectRatio={0.5}
        TopBarImg={TopBarImg}
        BottomBarImg={BottomBarImg}
      />
      <Wedge
        WedgeImg={BottomHalfWedgeImg}
        wedgeTopAspectRatio={0}
        wedgeBottomAspectRatio={0.55}
        TopBarImg={TopBarImg}
        BottomBarImg={BottomBarImg}
      />
    </div>
  );
}

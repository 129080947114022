import Definition from "../templates/Definition";
import ExampleContainer from "../templates/ExampleContainer";
import Example from "../templates/Example";
import History from "../templates/History";
import Image from "../templates/Image";
const SunkCostImg = require("lib/img/concepts/SunkCost.png");
export default function SunkCostFallacy() {
  return (
    <div>
      <Definition>
        <p>
          While assessing projects, managers tend to lend more weight to costs that have already
          been incurred rather than the costs to come. Not wanting past efforts go to waste,
          managers then allocate interminable growth of scope and cost of failing projects. This is
          the sunk cost fallacy, an understanding of this concept can help leaders know when to end
          projects.
        </p>
        <p>
          Example - Blackberry. Despite all societal trends moving towards smartphone technology,
          the phone mogul continued to invest heavily in the proprietary Blackberry technology. This
          disabled the company from adapting with changing customer preferences and ultimately being
          left behind in the cellular market.
        </p>
      </Definition>
      <Image src={SunkCostImg} alt="" />
      <ExampleContainer>
        <Example header='"Forward Waste"'>
          In the 1990s, science was enamored with a particle accelerator project. It was a
          scientific experiment using a vast array of resources with neither end nor useful
          application anywhere in sight. When questioned about the project, it was defended by
          citing all the resources expended that would now be "wasted." In 1993 Congress ultimately
          shut down the project. The stated reason was to avoid "forward waste." This perfectly
          exemplifies the sunk cost fallacy in that the engineers wanted to continue based on the
          effort and resources put into the failing project. Congress epitomizes the leader able to
          see past the current waste by instead focusing on the future costs and a project's ability
          to create sustainable value.
        </Example>
        <Example header="Blockbuster">
          This video rental giant famously clung to its brick-and-mortar model despite the rise of
          online streaming services like Netflix. Despite heavy investments in its physical stores,
          Blockbuster was slow to adapt, leading to its eventual downfall.
        </Example>
        <Example header="Kodak">
          This company dominated the film photography market for decades, investing heavily in
          research and development. However, when digital cameras emerged, Kodak was slow to shift
          its focus, clinging to its sunk costs in film technology. This ultimately led to the
          company's bankruptcy.
        </Example>
        <Example header="Yahoo">
          Once a leading internet search engine and portal, Yahoo struggled to compete with Google's
          innovative approach. Despite acquiring multiple companies in an attempt to catch up, Yahoo
          failed to adjust its core strategy and ultimately lost significant market share.
        </Example>
      </ExampleContainer>
      <History>
        <p>
          Although the fallacy originated in the 20th century, the idea of the sunk costs had roots
          long before. In the 17th century, the French philosopher Blaise Pascal observed the
          tendency to persist with poor investments despite their irrationality. In the 18th
          century, the British economist Adam Smith noted the phenomenon of "sunk costs" which
          influenced financial & economic decisions.
        </p>
        <p>
          Even with early underpinnings, it wasn't until 1972 that psychologists Amos Tversky and
          Daniel Kahneman, Nobel laureates in economics, created the foundation of the sunk-cost
          fallacy within publications about cognitive biases. Finally, in 1980, Richard Thaler, also
          a Nobel economic laureate, coined the term "sunk-cost fallacy" in his research of
          behavioral economics. This emphasized how individuals often disregard the "sunk-costs"
          ultimately leading to sub-optimal outcomes.
        </p>
        <p>
          Following the formalization of the concept it was widely used in research papers in the
          1980s and 1990s. One main form of popularization was its inclusion in psychology books
          such as the famous, Thinking Fast and Slow, by Daniel Kahneman. In the 2000s, McKinsey
          adopted the fallacy as a framework for analyzing business decisions. Today, the framework
          is used widely in a variety of fields helping us understand and mitigate irrational
          decision-making processes.
        </p>
      </History>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}

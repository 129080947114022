// use this variable to switch between local and server api
const useLocalApi = false;
const localApiUrl = "http://localhost:8080";

const apiUrl =
  useLocalApi && process.env.NODE_ENV === "development"
    ? localApiUrl
    : process.env.REACT_APP_API_URL;

const constants = {
  contentWidth: "800px",
  paragraphWidth: "50em",
  maxPageWidth: "1250px",
  sidebarWidth: 350,
  sidebarExpandedWidth: 20,
  sidebarCollapsedWidth: 3,
  apiUrl,
  navHeight: 60,
  colors: {
    tan1: "#fffaf0",
    tan1CssFilter:
      "invert(92%) sepia(31%) saturate(212%) hue-rotate(32deg) brightness(109%) contrast(109%)",
    tan2: "#ffefd0",
    tan2CssFilter:
      "invert(83%) sepia(24%) saturate(353%) hue-rotate(336deg) brightness(110%) contrast(103%)",
    tan3: "#edd3a8",
    tan3CssFilter:
      "invert(80%) sepia(20%) saturate(463%) hue-rotate(354deg) brightness(108%) contrast(86%)",
    tan4: "#d4b26a",
    tan4CssFilter:
      "invert(79%) sepia(42%) saturate(434%) hue-rotate(356deg) brightness(86%) contrast(91%)",
    tan5: "#be804b",
    tan5CssFilter:
      "invert(55%) sepia(48%) saturate(496%) hue-rotate(346deg) brightness(90%) contrast(87%)",
    darkTan: "#9d6739",
    darkTanCssFilter:
      "invert(41%) sepia(5%) saturate(4968%) hue-rotate(345deg) brightness(102%) contrast(89%)",
    darkerTan: "#6F4828",
    darkerTanCssFilter:
      "invert(26%) sepia(11%) saturate(2827%) hue-rotate(345deg) brightness(100%) contrast(82%)",
    lightGreyBlue: "#a0c1dd",
    lightGreyCssFilter:
      "invert(80%) sepia(21%) saturate(490%) hue-rotate(172deg) brightness(93%) contrast(86%)",
    greyBlue: "#396f9d",
    greyBlueCssFilter:
      "invert(35%) sepia(83%) saturate(410%) hue-rotate(166deg) brightness(92%) contrast(82%)",
    greyRed: "#9D393D",
    greyRedCssFilter:
      "invert(29%) sepia(55%) saturate(1424%) hue-rotate(322deg) brightness(80%) contrast(87%)",
    lightGreyRed: "#BE4B50",
    lightGreyRedCssFilter:
      "invert(43%) sepia(44%) saturate(843%) hue-rotate(308deg) brightness(81%) contrast(91%)",
  },
} as const;

export default constants;

import { TCreateUserReq } from "@sharedTypes/req.type";
import { TCreateUserRes, TErrorRes } from "@sharedTypes/res.type";
import constants from "Constants";
import missingParametersError from "api/Error";
import axios from "axios";

export async function CreateUser(
  firstName: string,
  lastName: string,
  email: string,
  password: string
) {
  const apiUrl = constants.apiUrl + "/auth/createuser";

  const body: TCreateUserReq = {
    firstName,
    lastName,
    email,
    password,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TCreateUserRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}

import { TStudent } from "types/JsonType";

export const Students = [
  {
    firstName: "Corban",
    lastName: "Procuniar",
    imgSrc: require("lib/img/team/c_procuniar.jpg"),
    major: "Masters in Information Systems Management",
    role: "Web Developer",
    started: new Date("2023/10/28"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/corbanprocuniar",
  },
  {
    firstName: "Tyler",
    lastName: "Hill",
    imgSrc: require("lib/img/team/t_hill.jpg"),
    major: "Strategic Management",
    role: "Research Assistant",
    started: new Date("2024/01/01"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/tylerhillconnect/",
  },
  {
    firstName: "Jonah",
    lastName: "Heninger",
    imgSrc: require("lib/img/team/j_heninger.jpg"),
    major: "Strategic Management",
    role: "Research Assistant",
    started: new Date("2024/04/01"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/jonah-heninger-b22977292",
  },
  {
    firstName: "Davis",
    lastName: "Rush",
    imgSrc: require("lib/img/team/d_rush.jpg"),
    major: "Strategic Management",
    role: "Research Assistant",
    started: new Date("2024/04/01"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/davis-rush-1654a8294",
  },
  {
    firstName: "Andres",
    lastName: "Rich",
    imgSrc: require("lib/img/team/a_rich.jpg"),
    major: "Advertising Content Creating",
    role: "Media Specialist",
    started: new Date("2024/02/01"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/andr%C3%A9s-rich/",
  },
  {
    firstName: "Calvin",
    lastName: "Scott",
    imgSrc: require("lib/img/team/c_scott.jpg"),
    major: "Advertising",
    role: "Media Manager/Creator",
    started: new Date("2024/02/01"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/calvin-scott-5925722a4/",
  },
] as const satisfies TStudent[];

export default Students as TStudent[];

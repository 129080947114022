import Definition from "components/concepts/templates/Definition";
import ExampleContainer from "../templates/ExampleContainer";
import Example from "../templates/Example";
import RelatedFrameworks from "../templates/RelatedFrameworks";
import { Concepts } from "lib/data/Concepts";
import History from "../templates/History";

export default function AdjacentMarketExpansion() {
  return (
    <div>
      <Definition>
        <p>
          Many companies may wonder how to best expand their portfolio. Adjacent markets are rife
          with opportunity while usually offering a solution that is similar to your existing
          product line. In a basic sense, Adjacent market expansion entails moving into a market
          closely related to their present one. As such, expansion in this sector can mean
          maintaining low costs and leveraging existing relationships.
        </p>
        <p>
          Harley-Davidson, the iconic motorcycle manufacturer, has built a strong brand identity
          around freedom and rebellion. They successfully expanded into a line of branded clothing
          and accessories. This taps into the existing customer loyalty and extends the brand
          experience beyond motorcycles. The same customer who buys a Harley might also want a
          Harley jacket to express their affiliation with the brand.
        </p>
      </Definition>
      <ExampleContainer>
        <Example header="Clorox">
          while being known for bleach and disinfectants recognized demand for eco-friendly
          alternatives, they introduced “Green Works” which is a line of plant-based cleaning
          products. This caters to a similar cleaning need but focuses on sustainability. This
          enabled the company to access a new market segment of eco-cognizant customers.
        </Example>
        <Example header="P&G">
          the cosmetics and grooming giant recently acquired Brita Water purification systems. They
          could easily leverage their existing knowledge of personal cleanliness and apply it to
          water filtration. This opened up a new market for them to pursue adding to their gigantic
          reach.
        </Example>

        <Example header="Michelin">
          The tire company renowned for its quality and durability, famously expanded into
          restaurant guides. This might seem like a leap, but it leverages their expertise in
          understanding long journeys and the importance of pit stops. Their restaurant guides cater
          to a similar customer base with a focus on quality and providing a positive travel
          experience, but in a completely different way.
        </Example>
      </ExampleContainer>
      <RelatedFrameworks
        concepts={[Concepts.ThreeHorizons, Concepts.BlueOcean, Concepts.PortersFiveForces]}
      />
      <History>
        <p>
          The concept of adjacent market expansion isn't formally traced back to one specific point
          in history, but rather has evolved through business practices over time. However, there
          are key thinkers and milestones that highlight its growing importance:
        </p>
        <p>
          Throughout history, companies have intuitively expanded into adjacent markets. For
          instance, a blacksmith might start selling nails or horseshoes alongside their core
          service. These early examples lacked a formal framework, but they demonstrate the
          underlying logic of leveraging existing capabilities.
        </p>

        <p>
          Management thinkers in the 20th century like Peter Drucker emphasized the importance of
          companies focusing on their core competencies. This philosophy laid the groundwork for
          strategic expansion into areas that utilize those core strengths.
        </p>

        <p>
          The concept gained more specific recognition in the 1990s with the rise of competitive
          strategy frameworks like Michael Porter's Five Forces. Businesses began to analyze not
          just their core market but also adjacent markets to identify growth opportunities with
          less competition.
        </p>

        <p>
          Building on competitive strategy, W. Chan Kim and Renée Mauborgne introduced the concept
          of Blue Ocean Strategy in their influential 2005 book. They argued that companies should
          not just compete in existing markets (red oceans) but seek out entirely new market spaces
          (blue oceans) - often achieved through adjacent market expansion with innovation.
        </p>

        <p>
          Today, adjacent market expansion is a widely recognized strategy for companies seeking
          growth. It offers a balance between the risks of entirely new ventures and the saturation
          of existing markets. Companies across various industries are constantly exploring and
          implementing this approach.
        </p>
      </History>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}
